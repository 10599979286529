import React, { useMemo, useState } from "react";

import { Box, Icon, useDisclosure } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import { RootState } from "../../store/store";
import { NotesModal } from "../Modal/NotesModal";
import { Table } from "../Table";
import { draftNote, Note, Role } from "../../types";
import { deleteNote, sendNote, updateNote } from "../../store/Form";
import { ShowError } from "../ErrorBoundary/ShowError";
import { useNotification } from "../../hooks";
import { userHasRequiredRole } from "../../utils";
import { NOTES_TABLE_COLUMNS } from "../../constants/notesTableColumns";
import dateFormat from "dateformat";

export type Props = {
  uuidNumber: string;
  appraisalId: number;
};

export const AddNotes: React.FC<Props> = ({ uuidNumber, appraisalId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { noteStore, notesError } = useSelector((state: RootState) => state.form);
  const { username, roles } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const showToast = useNotification();

  const [text, setText] = useState<string>("");
  const [editedId, setEditedId] = useState<number>();

  const columns = useMemo(() => NOTES_TABLE_COLUMNS, []);

  const onDelete = (item: Note) => {
    if (userHasRequiredRole(roles, [Role.Admin]) || item.createdBy.username === username) {
      dispatch(
        deleteNote({
          id: item.id,
          cb: () =>
            showToast({
              title: "Success",
              description: "Deleted note",
              status: "success",
            }),
        })
      );
    } else {
      showToast({
        title: "Error",
        description: "Dont have enough permissions",
        status: "error",
      });
    }
  };

  const closeModal = () => {
    setText("");
    setEditedId(undefined);
    onClose();
  };

  const getToolTip = (item: any) => {
    return "Created by: "+ item.createdBy.username +" <br /> at: " +
        dateFormat(item.createdAt, "dd.mm.yyyy HH:MM", true) + "<br />" +
        "Updated by: " + (item.updatedBy ? item.updatedBy.username : "-") +" <br />"+
        "at: " + (item.updatedAt ? dateFormat(item.updatedAt, "dd.mm.yyyy HH:MM", true) : "-" )
  };

  const onEdit = (item: Note) => {
    if (userHasRequiredRole(roles, [Role.Admin]) || item.createdBy.username === username) {
      setText(item.text);
      setEditedId(item.id);
      onOpen();
    } else {
      showToast({
        title: "Error",
        description: "Dont have enough permissions",
        status: "error",
      });
    }
  };

  const onSubmit = (data: { note: string }) => {
    if (editedId != undefined) {
      const editNote: draftNote = {
        id: editedId,
        appraisal: appraisalId,
        text: data.note,
        updatedAt: new Date(),
        cb: () =>
          showToast({
            title: "Success",
            description: "Updated note",
            status: "success",
          }),
      };
      dispatch(updateNote(editNote));
      setText(data.note);
      closeModal();
    } else {
      const id = 0;
      const draftNote: Note = {
        id,
        text: data.note,
        appraisal: { id: appraisalId },
        createdBy: { id, username },
        createdAt: new Date(),
      };
      dispatch(
        sendNote({
          notes: draftNote,
          cb: () => showToast({ title: "Success", description: "sent note", status: "success" }),
        })
      );
      closeModal();
    }
  };

  if (notesError) {
    return <ShowError error={notesError} />;
  }

  const clearText = () => {
    setText("");
    onOpen();
  };

  return (
    <>
      <Box flexGrow="1" minH="200" mr="10px">
        <Box padding="5px" backgroundColor="#e4e4e4" display="flex" alignItems="center" justifyContent="space-between">
          <p>Notizen</p>
          <Icon style={{ cursor: "pointer" }} as={AddIcon} onClick={uuidNumber ? clearText : onClose} />
        </Box>
        {!noteStore.length ? (
          "no data"
        ) : (
          <Table
            columns={columns}
            data={noteStore}
            isNote
            onEdit={onEdit}
            onDelete={onDelete}
            actionBtn
            pagination
            paginateSize={9999}
            getToolTip={getToolTip}
          />
        )}
      </Box>
      <NotesModal onClose={closeModal} onSubmit={onSubmit} isOpen={isOpen} text={text} />
    </>
  );
};
