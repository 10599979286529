import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Credentials, Tokens } from "../../types/auth";

export const login = createAsyncThunk<Tokens, Credentials>("auth/login", async (params, { rejectWithValue }) => {
  try {
    const { cb } = params;
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/login`, params);
    cb && cb();
    return response?.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
