import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: {
      400: "#ff7043",
      500: "#ff5722",
    },
    secondary: {
      100: "#263238",
    },
    white: "#ffff",
    grey: "#B0B0B0",
  },
});
