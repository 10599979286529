import { sortVisit, sortStatus } from "../utils";
import { formatDate } from "../utils/date";

export const MAIN_TABLE_COLUMNS = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Ref Nr",
    accessor: "refNr",
  },
  {
    Header: "Vorname",
    accessor: "firstName",
  },
  {
    Header: "Familienname",
    accessor: "lastName",
  },
  {
    Header: "Besuchsdatum",
    accessor: (row: { visit: any }) => formatDate(row.visit),
    sortType: sortVisit,
  },
  {
    Header: "Status",
    accessor: "status.name",
    sortType: sortStatus,
  },
  {
    Header: "Bearbeiter",
    accessor: "assignee",
  },
  {
    Header: "Auftraggeber",
    accessor: "client",
  },
  {
    Header: "Kategorie",
    accessor: "category",
  },
  {
    Header: "Settings",
    accessor: "settings",
  },
];
