import { AppUsers, IRole } from "../types";

export const userHasRequiredRole = (roles: string[], reqRoles: string[]) => {
  return roles
    .map((role) => {
      return reqRoles.some((reqRole) => reqRole === role);
    })
    .some((el) => el === true);
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertStringToFriendly = (data: string) => {
  return capitalizeFirstLetter(data.toLowerCase().replace("role_", ""));
};

export const convertToFriendlyName = (data: IRole[]) => {
  return data.map((role) => {
    return {
      ...role,
      name: convertStringToFriendly(role.name),
    };
  });
};

export const concatRolesAndUsers = (data: AppUsers[], roles: any) => {
  if (data.length === 0) return data;
  const rolesAndUsers = data.map((el: AppUsers) => {
    return { ...el, role: roles.find((i: any) => i.id === el.role.id) };
  });

  return rolesAndUsers.map((el: AppUsers) => {
    return {
      id: el.id,
      username: el.username,
      firstName: el.firstName ?? null,
      lastName: el.lastName ?? null,
      roleName: convertStringToFriendly(el.role.name),
      role: { ...el.role, name: convertStringToFriendly(el.role.name) },
      locked: el.locked,
      lockedName: el.locked ? "locked" : "not locked",
    };
  });
};

export const sortStatus = (rowA: any, rowB: any, id: number, desc: string) => {
  if (rowA.original["status"]?.sortOrder > rowB.original["status"]?.sortOrder) return 1;
  if (rowB.original["status"]?.sortOrder > rowA.original["status"]?.sortOrder) return -1;
  return 0;
};

export const sortCategory = (rowA: any, rowB: any, id: number, desc: string) => {
  if (rowA.original["attachmentType"]?.sortOrder > rowB.original["attachmentType"]?.sortOrder) return 1;
  if (rowB.original["attachmentType"]?.sortOrder > rowA.original["attachmentType"]?.sortOrder) return -1;
  return 0;
};

export const sortVisit = (rowA: any, rowB: any, id: number, desc: string) => {
  if (rowA.original["visit"] > rowB.original["visit"]) return 1;
  if (rowB.original["visit"] > rowA.original["visit"]) return -1;
  return 0;
};