import axios from "axios";
import { logout, setTokens } from "../store/auth";

import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 15 * 60 * 1000 //15 Minutes
})

export const axiosConfig = (dispatch: any, tokens?: any): any => {
  axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
  axios.defaults.adapter = cache.adapter;
  if (tokens != null) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokens.token}`;
  }
  axios.defaults.headers.post["Content-Type"] = "application/json";

  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      axios.interceptors.response.eject(interceptor);
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        tokens &&
        error.response.data.message === "Expired JWT Token"
      ) {
        originalRequest._retry = true;

        return axios
          .post(`${process.env.REACT_APP_BASE_URL}/api/token/refresh`, { refreshToken: tokens?.refreshToken })
          .then((res) => {
            if (res.status === 200) {
              dispatch(setTokens(res.data));
              originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
              return axios(originalRequest);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              tokens = null;
              dispatch(logout());
              location.reload();
            }
          });
      }

      if (
        error.response.status === 401 &&
        error.response.data.message === "Invalid JWT Token"
      ) {
          tokens = null;
          console.log("dispatch")
          dispatch(logout());
          location.reload();
       }
      return Promise.reject(error);
    }
  );
};
