import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DecodedToken, Tokens } from "../../types/auth";
import { login } from "./authActions";
import jwt_decode from "jwt-decode";

const convertTokenData = (token: string) => {
  try {
    const decodedToken: DecodedToken = jwt_decode(token);
    if (!decodedToken) return null;
    const { exp, iat, roles, username } = decodedToken;
    return {
      expirationTime: exp,
      issuedAt: iat,
      roles,
      username,
    };
  } catch (e) {
    return null;
  }
};

const updateStateFromToken = (state: AuthState, token: string) => {
  const decodedToken = convertTokenData(token);
  if (!decodedToken) {
    state.error = "Invalid token";
    return;
  }
  const { username, roles, expirationTime } = decodedToken;
  state.isSignedIn = true;
  state.username = username;
  state.roles = roles;
  state.expirationTime = expirationTime;
};

export interface AuthState {
  isSignedIn: boolean;
  username: string;
  roles: string[];
  expirationTime: Date | null;
  tokens: Tokens | null;
  error: string | null;
  isLoading: boolean;
}

const initialState: AuthState = {
  isSignedIn: false,
  username: "",
  roles: [],
  expirationTime: null,
  tokens: null,
  error: null,
  isLoading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state = initialState) => {
      state = initialState;
      return initialState;
    },
    setTokens: (state: AuthState, action: PayloadAction<Tokens>) => {
      const { token, refreshToken } = action.payload;
      state.isLoading = false;
      state.error = null;
      state.tokens = { token, refreshToken };
      updateStateFromToken(state, token);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state: AuthState, action: PayloadAction<Tokens>) => {
        const { token, refreshToken } = action.payload;
        state.isLoading = false;
        state.error = null;
        state.tokens = { token, refreshToken };
        updateStateFromToken(state, token);
      })
      .addCase(login.pending, (state: AuthState) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(login.rejected, (state: AuthState, action: any) => {
        state.isLoading = false;

        state.error = action.payload?.message ?? "Error";
      });
  },
});

export const { logout, setTokens } = authSlice.actions;

export default authSlice.reducer;
