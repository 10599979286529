import { ToastId, useToast } from "@chakra-ui/react";
import { Toast } from "../types";

type NotificationMenager = ({ title, description, status }: Toast) => ToastId | undefined;

export const useNotification = (): NotificationMenager => {
  const toast = useToast();

  const showToast = ({ title, description, status }: Toast) => {
    return toast({
      title: title,
      description: description,
      status: status,
      duration: 2000,
      isClosable: true,
      position: "top-right",
    });
  };
  return showToast;
};
