/* eslint-disable react/display-name */
import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import React from "react";

type Props = {
  placeholder?: string;
  disabled?: boolean;
  data?: Array<any>;
  text: string;
  defaultValue?: any;
  type?: any;
  error?: any;
  helperText?: string;
  file?: boolean;
};

const StyledSelect = React.forwardRef<any, Props>(
  ({ placeholder, disabled, data, text, defaultValue, helperText, error, file, ...props }, ref) => {
    return (
      <FormControl isInvalid={error}>
        <FormLabel>{text}</FormLabel>
        <Select ref={ref} isFullWidth placeholder={placeholder} disabled={disabled} {...props}>
          {data
            ? data.map((item) => (
                <option key={item.id} value={file ? item : item.id}>
                  {item.name || item.lastName + " " + item.firstName}
                </option>
              ))
            : null}
        </Select>
        <FormErrorMessage>{helperText}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default StyledSelect;
