import React, { useState } from "react";
import { AppUsers, Category, Filter, Status } from "../../types";
import { Client } from "../../types/home";
import type { Ref } from "react";

import Select from "react-select";

type Props<T> = {
  items: T[];
  label?: string;
  onClick?: (item: T) => void;
  styles?: any;
  value?: any;
  isLoading: boolean;
};

function getName(item: any) {
  if (!item) return null;
  return item.name ? item.name : item.username;
}

const _NewDropDown = <T extends string | Client | Category | Status | AppUsers>(
  { items, label = "menu", onClick, value, styles, isLoading, ...props }: Props<T>,
  ref: Ref<HTMLButtonElement>
) => {
  const options = items.map((d) => ({
    item: d,
    value: typeof d === "string" ? d : d.id,
    label: typeof d === "string" ? d : getName(d),
  }));

  const [selectValue, setSelectValue] = useState<Filter | null>(
    value
      ? {
          item: items.map((d) => d),
          value: value.id,
          label: getName(value),
        }
      : null
  );

  return (
    <div style={{ width: "200px", height: "50px" }}>
      <Select
        placeholder={label}
        value={selectValue}
        styles={styles}
        isSearchable={true}
        isClearable={true}
        isLoading={isLoading}
        onChange={(option) => {
          onClick && onClick(option ? (option.item as T) : ("" as unknown as T));
          onClick &&
            setSelectValue(
              option
                ? {
                    value: option?.value,
                    label: option?.label,
                    item: option,
                  }
                : null
            );
        }}
        options={options as any}
      />
    </div>
  );
};

const NewDropDown = React.forwardRef(_NewDropDown) as typeof _NewDropDown;

export { NewDropDown };
