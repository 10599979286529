import { Appraisals } from "../../types";

export const convertClientsData = async (data: Appraisals[]) => {
  return data.map((i) => {
    return {
      id: i.id,
      refNr: i.refNr,
      firstName: i.firstName,
      lastName: i.lastName,
      status: i.status ?? null,
      uuid: i.uuid,
      visit: i.visit,
      client: i.client?.name ?? null,
      assignee: i.assignee?.username.slice(0, 4) ?? null,
      category: i.category?.name ?? null,
      address: i.address ?? null,
      createdAt: i.createdAt,
      createdBy: i.createdBy
    };
  });
};
