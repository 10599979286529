import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";

import { ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from "@chakra-ui/modal";
import { Flex, FormErrorMessage, Select, Text } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Input } from "@chakra-ui/input";
import { CustomModal } from "../Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import StyledAlert from "../StyledAlert/StyledAlert";
import { FileUploader } from "react-drag-drop-files";
import { FileType, UploadFileType } from "../../types";
import { AttachmentIcon } from "@chakra-ui/icons";
import { useState } from "react";
// import { ACCEPTED_FILE_TYPES } from "../../constants/mock";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  attachmentTypes: any;
  loading: boolean;
  error: any;
  data?: FileType | null;
};
type Input = {
  file: UploadFileType | FileType | FileList | null;
  category: number;
  description: string;
  isEditMode: boolean;
};

const FilesModal = ({ isOpen, onClose, onSubmit, attachmentTypes, loading, error, data }: Props) => {
  const isEditMode = !!data;

  useEffect(() => {
    if (isEditMode) {
      setValue("isEditMode", true);
      setValue("file", data);
      setValue("category", data.category.id);
      setValue("description", data.description);
    } else {
      reset();
    }
  }, [data]);

  const schema = yup.object().shape({
    // file: yup.mixed().when("isEditMode", {
    //   is: false,
    //   then: yup.mixed().test("name", "File is required", (value) => {
    //     return value && value.name;
    //   }),
    // }),
    category: yup.number().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Input>({
    defaultValues: { file: null, category: 9, description: "", isEditMode: false },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setFile(0);
    }
  }, [handleSubmit, reset, isSubmitSuccessful]);
  
  const [fileList, setFile] = useState(0);

  const handleChange = (files: FileList) => {
    setValue("file", files, { shouldValidate: true })
    setFile(files.length);

  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalCloseButton />
      <ModalHeader>{isEditMode ? "Edit file" : "Upload file"} </ModalHeader>
      <ModalCloseButton />

      <ModalBody pb={6}>
        <>
          <FormControl isInvalid={!!errors.file}>
            <FormLabel>File</FormLabel>
            <label
              htmlFor="file"
              style={{
                display: "inline-block",
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              {isEditMode ? (
                <Flex>
                  <AttachmentIcon boxSize={22} mr={5} />
                  <Text>{data.file}</Text>
                </Flex>
              ) : (
                <>
                <FileUploader
                  multiple={true}
                  handleChange={handleChange}
                  name="files"
                  //types={ACCEPTED_FILE_TYPES}
                  file={error && null}
                />
                <small>{fileList ? `Anzahl Dateien: ${fileList}` : "Noch keine Dateien"}</small>
                </>
              )}
            </label>
            <FormErrorMessage justifyContent="center">{errors.file?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.category} mt={4}>
            <FormLabel>Kategorie</FormLabel>
            <Select {...register("category", { valueAsNumber: true })} placeholder="Open this select menu">
              {attachmentTypes &&
                attachmentTypes.map((type: any) => (
                  <option key={type.id} value={Number(type.id)}>
                    {type.name}
                  </option>
                ))}
            </Select>
            <FormErrorMessage justifyContent="center">Category is a required field</FormErrorMessage>
          </FormControl>
        </>

        <FormControl isInvalid={!!errors.description} mt={4}>
          <FormLabel>Beschreibung</FormLabel>
          <Textarea {...register("description")} type="textarea" placeholder="Beschreibung" />
          <FormErrorMessage justifyContent="center">{errors.description?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      {error && <StyledAlert error={error} />}
      <ModalFooter>
        <Button onClick={handleSubmit(onSubmit)} colorScheme="blue" mr={3} isLoading={loading}>
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </CustomModal>
  );
};

export default FilesModal;
