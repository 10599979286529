import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppUsers, Category, Client, Order, Status } from "../../types";

export interface FiltersState {
  client: Client | undefined;
  category: Category | undefined;
  status: Status | undefined;
  assignee: AppUsers | undefined;
  filter: Order | undefined;
}

const initialState: FiltersState = {
  client: undefined,
  category: undefined,
  status: undefined,
  assignee: undefined,
  filter: "Meine Aufträge",
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateClientFilter: (
      state: FiltersState,
      action: PayloadAction<{
        client: Client | undefined;
      }>
    ) => {
      const { client } = action.payload;
      state.client = client;
    },
    updateCategoryFilter: (
      state: FiltersState,
      action: PayloadAction<{
        category: Category | undefined;
      }>
    ) => {
      const { category } = action.payload;
      state.category = category;
    },
    updateStatusFilter: (
      state: FiltersState,
      action: PayloadAction<{
        status: Status | undefined;
      }>
    ) => {
      const { status } = action.payload;
      state.status = status;
    },
    updateAssigneeFilter: (
      state: FiltersState,
      action: PayloadAction<{
        assignee: AppUsers | undefined;
      }>
    ) => {
      const { assignee } = action.payload;
      state.assignee = assignee;
    },
    updateOrderFilter: (
      state: FiltersState,
      action: PayloadAction<{
        filter: Order | undefined;
      }>
    ) => {
      const { filter } = action.payload;
      state.filter = filter;
    },
  },
});

export const { updateCategoryFilter, updateClientFilter, updateStatusFilter, updateAssigneeFilter, updateOrderFilter } =
  filtersSlice.actions;

export default filtersSlice.reducer;
