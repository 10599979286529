import { Container } from "@chakra-ui/layout";
import React from "react";

export const MainContainer: React.FC = ({ children, ...props }) => {
  return (
    <Container margin="0 auto" display="flex" maxW="1500" {...props}>
      {children}
    </Container>
  );
};
