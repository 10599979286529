import { useDisclosure } from "@chakra-ui/hooks";
import { Button, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoading } from "../components/CustomLoading";
import { ShowError } from "../components/ErrorBoundary/ShowError";
import { PasswordResetModal, UserModal } from "../components/Modal";
import { useNotification } from "../hooks";
import { Table } from "../components/Table";
import { USERS_TABLE_COLUMNS } from "../constants/usersTableColumns";
import { fetchAppUsers } from "../store/home";
import { RootState } from "../store/store";
import { createUser, resetPassword, updateUser } from "../store/user";
import { AppUsers } from "../types";
import { concatRolesAndUsers } from "../utils";

const Admin = () => {
  const { appUsers, loading, error, roles } = useSelector((state: RootState) => state.home);
  const { error: errorUser, loading: loadingModal, newPassword } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const showToast = useNotification();

  const [user, setUser] = useState<AppUsers>();
  const [isOpenResetModal, setIsOpenResetModal] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = () => {
    dispatch(fetchAppUsers());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = React.useMemo(() => concatRolesAndUsers(appUsers, roles), [appUsers]);
  const columns = React.useMemo(() => USERS_TABLE_COLUMNS, []);

  useEffect(() => {
    if (!loadingModal && !errorUser && isOpen) {
      onClose();
      fetchData();
    }
  }, [loadingModal, errorUser]);

  const onSubmit: SubmitHandler<AppUsers> = async (data) => {
    if (user) {
      await dispatch(
        updateUser({
          user: data,
          cb: () => showToast({ title: "Success", description: "Updated user", status: "success" }),
        })
      );
    } else {
      await dispatch(
        createUser({
          data: data,
          cb: () => showToast({ title: "Success", description: "Created user", status: "success" }),
        })
      );
    }
  };

  const resetPasswordClick = async (data: AppUsers) => {
    const { id } = data;
    dispatch(
      resetPassword({
        id: id,
        cb: () => showToast({ title: "Success", description: "Password reset", status: "success" }),
      })
    );
    setIsOpenResetModal(true);
  };

  const onRowPress = (data: any) => {
    setUser(data);
    onOpen();
  };

  if (loading) {
    return <CustomLoading />;
  }

  if (error) {
    return <ShowError error={error} onPress={fetchData} />;
  }

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        resetPassword={resetPasswordClick}
        onOpen={onOpen}
        onRowPress={onRowPress}
        actionBtn
        admin
        pagination
      />
      <Flex justifyContent="flex-end">
        <Button
          onClick={() => {
            setUser(undefined);
            onOpen();
          }}
          m="5"
        >
          Neuer Benutzer
        </Button>
      </Flex>

      <UserModal onClose={onClose} onSubmit={onSubmit} isOpen={isOpen} user={user} />
      <PasswordResetModal
        isOpen={isOpenResetModal}
        onClose={() => setIsOpenResetModal(false)}
        newPassword={newPassword}
        isLoading={loadingModal}
      />
    </div>
  );
};

export { Admin };
