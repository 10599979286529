import { Flex, Text, Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const AccessDenied: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      <Box borderBottom="1px solid" borderBottomColor={"grey"} width="40%" textAlign="center" p="4">
        <Text fontSize="4xl" fontWeight="bold">
          Access denied
        </Text>
      </Box>
      <Box p="4" textAlign="center">
        <Text>You do not have permission to access this page.</Text>
        <Text>Please contant your Site Administrator(s) to request access.</Text>
      </Box>
      <Button backgroundColor={"primary.400"} color={"white"} onClick={() => navigate(-1)}>
        Return back
      </Button>
    </Flex>
  );
};
