import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { persistor, store } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import { ErrorBoundary } from "./components/ErrorBoundary";
import { CustomLoading } from "./components/CustomLoading";
import { ErrorBoundary } from "./components/ErrorBoundary";

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<CustomLoading />} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);
