import dateFormat from "dateformat";

export const formatDate = (date: any) => {
  if (date) {
    return dateFormat(new Date(date), "dd.mm.yyyy HH:MM", true);
  } else {
    return date;
  }
};

export const convertStringToDate = (dateString: any) => {
  return new Date(dateString.split("+")[0])
}

export const formatDateToTimestamp = (dateString: any) => {
  const d = new Date(dateString);
  const date = d.toISOString().split("T")[0];
  const time = d.toTimeString().split(" ")[0];
  return `${date} ${time}`;
};
