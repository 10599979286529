import { createSlice } from "@reduxjs/toolkit";
import { createUser, resetPassword, updateUser } from ".";
import { changePassword } from ".";

interface InitialState {
  loading: boolean;
  error: string;
  newPassword: string;
}

const initialState: InitialState = { error: "", loading: false, newPassword: "" };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state: InitialState) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(createUser.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(createUser.rejected, (state: InitialState, action: any) => {
        state.error = action.payload?.message ?? action.error.message;
        state.loading = false;
      })
      .addCase(updateUser.fulfilled, (state: InitialState) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(updateUser.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(updateUser.rejected, (state: InitialState, action: any) => {
        state.error = action.payload?.message ?? action.error.message;
        state.loading = false;
      })
      .addCase(changePassword.fulfilled, (state: InitialState) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(changePassword.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(changePassword.rejected, (state: InitialState, action: any) => {
        state.error = action.payload?.message ?? action.error.message;
        state.loading = false;
      })
      .addCase(resetPassword.fulfilled, (state: InitialState, action) => {
        state.loading = false;
        state.error = "";
        state.newPassword = action.payload.newPassword;
      })
      .addCase(resetPassword.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(resetPassword.rejected, (state: InitialState, action: any) => {
        state.error = action.payload?.message ?? action.error.message;
        state.loading = false;
      });
  },
});

export default userSlice.reducer;
