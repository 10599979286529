import { ErrorType } from "../types";

export const errors: ErrorType[] = [
  {
    code: 401,
    apiResponse: "Invalid credentials.",
    message: "Invalid Credentials.",
  },
];

export const ERROR_INVALID_STATUS = "Invalid status";
export const ERROR_DUPLICATE_REF_NR = "Duplicate ref nr";
