import { sortCategory } from "../utils/common";

export const FILES_TABLE_COLUMNS = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "FileName",
    accessor: (row: { file: any }) => (row.file.length < 16 ? row.file : row.file.substring(0, 15) + "..."),
    //    accessor: "file",
  },
  {
    Header: "Beschreibung",
    accessor: (row: { description: any }) =>
      row.description ? (row.description.length < 16 ? row.description : row.description.substring(0, 15) + "...") : "",
    //    accessor: "description",
  },
  {
    Header: "Kategorie",
    accessor: "category.name",
    sortType: sortCategory,
  },
  {
    Header: "Aktion",
    accessor: "test",
  },
];
