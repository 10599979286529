import { Flex, Box, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { FILES_TABLE_COLUMNS } from "../constants/filesTableColumns";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  fetchAppraisals,
  fetchAppUsers,
  fetchAttachmentTypes,
  fetchCategories,
  fetchClients,
  fetchStatuses,
  updateAppraisal,
} from "../store/home";
import { RootState } from "../store/store";
import { AddFiles, CreateAppraisal, schema } from "../components/Form";
import { AddNotes } from "../components/Form/AddNotes";

import { formatDateToTimestamp } from "../utils";
import { fetchFiles, fetchNotes } from "../store/Form";
import { MainContainer } from "../components/Form/MainContiner";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { InfoIcon } from "@chakra-ui/icons";
import { CustomLoading } from "../components/CustomLoading";
import { APPRAISALS_MOCK } from "../constants/mock";
import { useNotification } from "../hooks";
import { ShowError } from "../components/ErrorBoundary/ShowError";
import { ERROR_DUPLICATE_REF_NR, ERROR_INVALID_STATUS } from "../constants/errors";
import { convertClientsData } from "../utils/helpers/convertClients";

export const Create: React.FC<any> = () => {
  const columns = useMemo(() => FILES_TABLE_COLUMNS, []);
  const appraisalDetails = useSelector((state: RootState) => state.home.appraisals);
  const { error } = useSelector((state: RootState) => state.home);

  const showToast = useNotification();

  const { id: pageID } = useParams();
  const { id, uuid, visit } = appraisalDetails?.[0] || {};

  const dispatch = useDispatch();
  const isEditMode = !!pageID;
  const navigate = useNavigate();

  const { clients, attachmentTypes, categories, appUsers, loading } = useSelector((state: RootState) => state.home);
  const { fileStore, noteStore } = useSelector((state: RootState) => state.form);
  const [uuidNumber, setUuidNumber] = useState(" ");

  const [fetched, setFetched] = useState<boolean>(false);

  const [appraisalId, setAppraisalId] = useState<number>(0);

  const { ...methods } = useForm({
    defaultValues: APPRAISALS_MOCK,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    if (!isEditMode) {
      setUuidNumber("");
    }

    batch(async () => {
      if (isEditMode) {
        await dispatch(fetchAppraisals({ uuid: pageID }));
      } else {
        dispatch(async () => {
            return await convertClientsData([APPRAISALS_MOCK]);
          }
        );
      }
      await dispatch(fetchClients());
      await dispatch(fetchAttachmentTypes());
      await dispatch(fetchAppUsers());
      await dispatch(fetchCategories());
      await dispatch(fetchStatuses());
      setFetched(true);
    });
  }, [dispatch, pageID]);

  useEffect(() => {
    if (fetched) {
      isEditMode && dispatch(fetchNotes(appraisalDetails[0].id));
      isEditMode && dispatch(fetchFiles(appraisalDetails[0].id));
    }
  }, [appraisalDetails, fetched]);

  const onSubmit = async (data: any) => {
    const entries = Object.entries(data).filter((entry) => entry[0] !== "id" && entry[0] !== "uuid");

    const formData: any = {};

    entries.forEach((entry: any) => {
      const isVisit = entry[0] === "visit";
      Object.assign(formData, {
        [entry[0]]: isVisit ? formatDateToTimestamp(entry[1]) : entry[1],
      });
    });

    if (isEditMode || uuidNumber) {
      dispatch(
        updateAppraisal({
          id: appraisalDetails[0].id,
          formData: formData,
          cb: () => showToast({ title: "Success", description: "Updated appraisal", status: "success" }),
        })
      );
    } else if (!isEditMode) {
      Object.keys(formData).forEach((k) => (formData[k] = formData[k] === "" ? null : formData[k]));
      //TODO: MOVE IT TO REDUX
      const res = await axios({
        method: "post",
        url: "/api/appraisals",
        data: formData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      setUuidNumber(res.data?.uuid);
      setAppraisalId(res.data?.id);
      res.data?.uuid && navigate(`/edit/${res.data?.uuid}`, { replace: true });
      showToast({ title: "Success", description: "Created appraisal", status: "success" });
    }
  };

  if (!fetched || loading) {
    return <CustomLoading />;
  }

  if (error && error != ERROR_INVALID_STATUS && error != ERROR_DUPLICATE_REF_NR) {
    return <ShowError error={error} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CreateAppraisal
          isEditMode={isEditMode}
          appraisalDetails={appraisalDetails}
          uuidNumber={isEditMode ? uuidNumber : uuid}
          appraisalId={appraisalId}
          pageID={pageID}
        />
        <Box pt={10} />
        <MainContainer>
          {isEditMode ? (
            <>
              <AddFiles uuidNumber={uuidNumber} appraisalId={appraisalDetails?.[0]?.id} />
              <AddNotes uuidNumber={uuidNumber} appraisalId={id} />
            </>
          ) : (
            <Flex align="center">
              <InfoIcon w={8} h={8} />
              <Text ml={5}>To upload files or notes, you need to submit appraisal first</Text>
            </Flex>
          )}
        </MainContainer>
      </form>
    </FormProvider>
  );
};
