import { Button, Input, InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppraisals, search } from "../../store/home/homeActions";
import { useLocation } from "react-router-dom";
import { RootState } from "../../store/store";
import { CloseIcon } from "@chakra-ui/icons";

const Search: React.FC = () => {
  const [query, setQuery] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();

  const { searching } = useSelector((state: RootState) => state.home);
  const isEnabled = location.pathname === "/home" ? false : true;

  const { client, category, status, assignee, filter } = useSelector((state: RootState) => state.filters);

  const onSearch = () => {
    dispatch(search({ query: query }));
  };

  const resetSearch = () => {
    setQuery("");
    dispatch(
      fetchAppraisals({
        clientId: client?.id,
        categoryId: category?.id,
        statusId: status?.id,
        assigneeId: assignee?.id,
        filter: filter ? filter : "Meine Aufträge",
      })
    );
  };

  return (
    <Stack direction="row" spacing={4} align="center" color={"black"}>
      <InputGroup>
        <Input
          variant="outline"
          placeholder="Suchen..."
          backgroundColor={"white"}
          disabled={isEnabled}
          value={query}
          onChange={(e: any) => setQuery(e.target.value)}
        />
        {query.length > 0 && !isEnabled && (
          <InputRightElement width="3.0rem">
            <CloseIcon onClick={resetSearch} />
          </InputRightElement>
        )}
      </InputGroup>

      <Button
        colorScheme="teal"
        variant="outline"
        color={"primary.400"}
        onClick={() => onSearch()}
        disabled={isEnabled}
        isLoading={searching}
      >
        Suchen
      </Button>
    </Stack>
  );
};

export default Search;
