export const NOTES_TABLE_COLUMNS = [
  {
    Header: "NOTE",
    accessor: "text",
  },

  {
    Header: "AKTION",
    accessor: "test",
    Cell: (row: any) => (
      <div style={{ textAlign: "end" }}>{row.value}eferfefr</div>
    ),
  },
];
