import React, { useEffect, useMemo, useState } from "react";

import { Box, Icon, useDisclosure } from "@chakra-ui/react";
import { Table } from "../Table";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import { FILES_TABLE_COLUMNS } from "../../constants/filesTableColumns";
import { downloadFile } from "../../store/home";

import { RootState } from "../../store/store";
import FilesModal from "../FilesModal/FilesModal";
import { deleteFile, sendFile, updateFile } from "../../store/Form";
import dateFormat from "dateformat";
import { FileType, Role } from "../../types";
import { useNotification } from "../../hooks";
import { userHasRequiredRole } from "../../utils";

export const AddFiles = ({ appraisalId, uuidNumber }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [data, setData] = useState<FileType | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const sortBy = [{ id: "category.name", desc: false }];

  const { attachmentTypes } = useSelector((state: RootState) => state.home);
  const { fileStore, loading, error } = useSelector((state: RootState) => state.form);
  const { username, roles } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const showToast = useNotification();

  const columns = useMemo(() => FILES_TABLE_COLUMNS, []);

  const onSubmit = ({ file, description, category }: any) => {
    if (!isEditMode) {
      Array.from(file).forEach(f => {
        const { id, name }: any = attachmentTypes.find((attachemnt) => attachemnt.id === category);
        const draftFile = {
          file: f as File,
          description,
          category: { id: id, name: name },
          categoryId: id,
          appraisalId,
          fileName: (f as File).name,
          attachedBy: { id: 0, username: username },
          cb: () =>
            showToast({
              title: "Success",
              description: "Added file",
              status: "success",
            }),
        };
        console.log(draftFile);
        dispatch(sendFile(draftFile));

      })

    } else {
      dispatch(
        updateFile({
          id: file.id,
          category: category,
          description: description,
          cb: () =>
            showToast({
              title: "Success",
              description: "Updated file",
              status: "success",
            }),
        })
      );
    }
  };

  useEffect(() => {
    if (!loading && !error && isOpen) {
      onClose();
    }
  }, [error, loading]);

  const onDelete = (file: FileType) => {
    if (userHasRequiredRole(roles, [Role.Admin]) || file.attachedBy?.username === username) {
      dispatch(
        deleteFile({
          id: file.id,
          cb: () =>
            showToast({
              title: "Success",
              description: "Deleted file",
              status: "success",
            }),
        })
      );
    } else {
      showToast({
        title: "Error",
        description: "Dont have enough permissions",
        status: "error",
      });
    }
  };

  const getToolTip = (data: any) => {
    return "Dateiname: " +
      data.file + "<br/>" +
      (data.description ? " Beschreibung: " + data.description : "") + "<br/>" +
      (data.attachedAt ? " Hochgeladen: " + dateFormat(data.attachedAt, "dd.mm.yyyy HH:MM", true) : "") + "<br/>" +
      (data.attachedBy ? " Von: " + data.attachedBy.username : "")
    ;
  };

  const onDownload = (file: FileType) => {
    dispatch(downloadFile({ id: file.id, fileName: file.file }));
  };

  const editFileHandler = (file: FileType) => {
    if (userHasRequiredRole(roles, [Role.Admin, Role.User]) || file.attachedBy!.username === username) {
      setData(file);
      setIsEditMode(true);
      onOpen();
    } else {
      showToast({
        title: "Error",
        description: "Dont have enough permissions",
        status: "error",
      });
    }
  };

  const openModal = () => {
    setIsEditMode(false);
    setData(null);
    onOpen();
  };

  // if (error) {
  //   return <ShowError error={error} />;
  // }

  return (
    <>
      <Box width="60%" minH="200" mr="10px">
        <Box padding="5px" backgroundColor="#e4e4e4" display="flex" alignItems="center" justifyContent="space-between">
          <p>Files</p>
          <Icon style={{ cursor: "pointer" }} as={AddIcon} onClick={openModal} />
        </Box>
        {fileStore ? (
          <Table
            columns={columns}
            data={fileStore}
            onDownload={onDownload}
            onDelete={onDelete}
            paginateSize={9999}
            getToolTip={getToolTip}
            pagination
            sortedBy={sortBy}
            onFileEdit={editFileHandler}
          />
        ) : (
          "no data"
        )}
      </Box>

      <FilesModal
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={onSubmit}
        attachmentTypes={attachmentTypes}
        loading={loading}
        error={error}
        data={data}
      />
    </>
  );
};

export type EditFileTypes = {
  description: string;
  category: string;
};
