import React, { useState } from "react";
import { Flex, Spacer, Text, Box, Button, Progress, useDisclosure } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { AppraisalAtachments, Images } from "../../types";
import { formatDate } from "../../utils/date";
import { Grid, GridItem } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { downloadFile } from "../../store/home";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import mp3Icon from "../../assets/icons/mp3.png";
import mp4Icon from "../../assets/icons/mp4.png";
import pdfIcon from "../../assets/icons/pdf.png";
import docIcon from "../../assets/icons/doc.png";

import zipIcon from "../../assets/icons/zip.png";
import xlxIcon from "../../assets/icons/xls.png";
import { PhotoModal } from "../Modal/PhotoModal";

type Props = {
  data: AppraisalAtachments[];
  images: Images[];
};

export const CustomList: React.FC<Props> = ({ data, images }) => {
  const dispatch = useDispatch();
  const { downloadingImage } = useSelector((state: RootState) => state.home);
  const [photo, setPhoto] = useState<string>("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getFileExtentionName = (file: string) => file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2).replace(" ", "").toLowerCase();

  return (
    <>
      {downloadingImage && (
        <Box m="5">
          <Progress size="xs" isIndeterminate />
        </Box>
      )}

      <Grid h="200px" templateColumns="repeat(3, 1fr)" gap={4} mt="5">
        {data.map((item) => {
          const showPhoto = () => {
            for (let i = 0; i < images.length; i++) {
              if (item.id === images[i].id) {
                return images[i].photo;
              }
            }
          };

          const photos = ["jpg", "jpeg", "png"];

          const checkIfPhoto = photos.some((photo) => {
            return getFileExtentionName(item.file) === photo;
          });

          const showIcon = (file: string) => {
            const extentionName = getFileExtentionName(file);
            switch (extentionName) {
              case "xlsx":
                return xlxIcon;
              case "mp3":
                return mp3Icon;
              case "mp4":
                return mp4Icon;
              case "zip":
                return zipIcon;
              case "pdf":
                return pdfIcon;
              case "doc":
                return docIcon;
            }
          };

          const savePhoto = () => {
            dispatch(downloadFile({ id: item.id, fileName: item.file, publicUrl: true }));
          };

          return (
            <GridItem border="1px solid black" m="2" borderRadius="10">
              <Flex borderBottom="1px solid black">
                <Box p="4">
                  <Text>{item.attachmentType.name}</Text> 
                </Box>
                <Spacer />
                <Box p="4">
                  <Text fontSize={10} justifyContent="right">({item.file})</Text>
                </Box>

              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <Box p="4">
                  <Image
                    src={checkIfPhoto ? `data:image/jpeg;base64,${showPhoto()}` : showIcon(item.file)}
                    width={"300px"}
                    style={checkIfPhoto ? { cursor: "pointer" } : { cursor: "auto" }}
                    onClick={() => {
                      if (checkIfPhoto) {
                        const foundId = images.findIndex((x) => x.id === item.id);
                        setPhoto(images[foundId].photo);
                        onOpen();
                      }
                    }}
                  />
                </Box>
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <Box p="4">
                  <Text fontSize="22" fontWeight="bold">
                    {item.description}
                  </Text>
                  <Text>{formatDate(item.attachedAt)}</Text>
                </Box>
                <Spacer />
                <Box p="4">
                  <Button backgroundColor="white" onClick={savePhoto}>
                    <DownloadIcon w={10} h={10} />
                  </Button>
                </Box>
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
      <PhotoModal onClose={onClose} isOpen={isOpen} photo={photo} />
    </>
  );
};
