import React, { useEffect, useState } from "react";
import { Flex, Text, Box, Center, Button, SimpleGrid, GridItem } from "@chakra-ui/react";
import { CustomList } from "../components/CustomList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { fetchAppraisalAttachments, fetchAppraisals, fetchFile } from "../store/home";
import { useNavigate, useParams } from "react-router-dom";
import { ShowError } from "../components/ErrorBoundary/ShowError";
import { CustomLoading } from "../components/CustomLoading";
import { EditIcon, WarningTwoIcon } from "@chakra-ui/icons";

export const AppraisalGallery: React.FC = () => {
  const { appraisals, appraisalDetails, appraisalImages, loading, error } = useSelector(
    (state: RootState) => state.home
  );
  const isSignedIn = useSelector((state:RootState) => state.auth.isSignedIn)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const { id } = useParams();

  const dispatch = useDispatch();

  const fetchData = async () => {
    setIsLoading(true);
    if (id) {
      await dispatch(fetchAppraisals({ uuid: id }));
      await dispatch(fetchAppraisalAttachments({ uuid: id }));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (appraisalDetails.length !== 0 && !isLoading) {
      appraisalDetails.map((item) => {
        dispatch(fetchFile({ id: item.id }));
      });
    }
  }, [isLoading, appraisalDetails]);

  if (error) {
    return <ShowError error={error} onPress={fetchData} />;
  }

  if (isLoading) {
    return <CustomLoading />;
  }

  return (
    <>
      <Flex borderBottom="1px solid #000" padding="0">
        <SimpleGrid columns={10} width={"100%"}>
          <GridItem colSpan={9}>
            <Flex alignItems="center" padding="5">
              <Box px="4">
                <Text fontSize="14">Ref Nr</Text>
              </Box>
              <Box>
                <Text fontWeight="bold" fontSize="18">
                  {appraisals[0].refNr}
                </Text>
              </Box>
              <Box px="4">
                <Text fontSize="14">Vorname</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">{appraisals[0].firstName}</Text>
              </Box>
              <Box px="4">
                <Text fontSize="14">Familienname</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">{appraisals[0].lastName}</Text>
              </Box>
              <Box px="4">
                <Text fontSize="14">Auftraggeber</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">{appraisals[0].client}</Text>
              </Box>
            </Flex>
          </GridItem>
          <GridItem display={"flex"} justifyContent={"flex-end"}>
            {isSignedIn ? (
              <Box px="4" >
                <Button onClick={() => navigate(`/edit/${appraisals[0].uuid}`)}>
                  <EditIcon />
                </Button>
              </Box>
            ) : ""}
          </GridItem>
        </SimpleGrid>
      </Flex>
      {!isLoading && !appraisalDetails.length ? (
        <Center mt={5}>
          <Flex flexDirection="column" alignItems="center">
            <WarningTwoIcon height={100} width={100} />
            <Text>Keine Dateien/Fotos gefunden</Text>
          </Flex>
        </Center>
      ) : (
        <CustomList data={appraisalDetails} images={appraisalImages} />
      )}
    </>
  );
};
