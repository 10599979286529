import { Flex } from "@chakra-ui/layout";
import { CircularProgress } from "@chakra-ui/react";

export const CustomLoading: React.FC = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="calc(100vh - 144px)">
      <CircularProgress isIndeterminate color="primary.400" />
    </Flex>
  );
};
