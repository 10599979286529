import React from "react";
import { CustomModal } from "./CustomModal";
import { ModalBody, ModalCloseButton, ModalHeader, ModalFooter, Text } from "@chakra-ui/react";
import { CustomLoading } from "../CustomLoading";
import { CustomButton } from "../CustomButton";

type Props = { isOpen: boolean; onClose: () => void; newPassword?: string; isLoading: boolean };

export const PasswordResetModal: React.FC<Props> = ({ isOpen, onClose, newPassword, isLoading }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Password Reset</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>{isLoading ? <CustomLoading /> : <Text>Das Passwort lautet: {newPassword}</Text>}</ModalBody>

      <ModalFooter>
        <CustomButton onClick={onClose} title="Schließen" />
      </ModalFooter>
    </CustomModal>
  );
};
