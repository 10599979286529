import React, { useEffect } from "react";
import {
  Flex,
  Box,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { CustomButton } from "../components/CustomButton";
import { CustomLoading } from "../components/CustomLoading";
import { fetchRoles } from "../store/home";
import { PasswordChange } from "../types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePassword } from "../store/user";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNotification } from "../hooks";

export const ChangePassword: React.FC = () => {
  const { error, loading } = useSelector((state: RootState) => state.user);

  const [show, setShow] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToast = useNotification();

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  const schema = yup.object().shape({
    password: yup.string().min(8).max(20).required(),
    repeatPassword: yup
      .string()
      .min(8)
      .max(20)
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordChange>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<PasswordChange> = (data) => {
    dispatch(
      changePassword({
        passwords: data,
        cb: () => showToast({ title: "Success", description: "Changed password", status: "success" }),
      })
    );
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Flex color="black" flexDirection="column" align="center" justify="center" height="calc(100vh - 144px)">
      <Box p="5">
        <Text fontSize="2xl">Passwort ändern</Text>
      </Box>

      {error && <Text color="red">{error}</Text>}

      <Box p="4" align="center" justifyContent="space-between">
        <FormControl mb="5" isInvalid={!!errors.oldPassword}>
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="aktuelles Passwort"
              {...register("oldPassword")}
            />
            <InputRightElement width="3.5rem">
              <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                {show ? <ViewIcon boxSize="22px" /> : <ViewOffIcon boxSize="22px" />}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage justifyContent="center">{errors.oldPassword?.message}</FormErrorMessage>
        </FormControl>

        <FormControl mb="5" isInvalid={!!errors.password}>
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="neues Passwort"
              {...register("password")}
            />
            <InputRightElement width="3.5rem">
              <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                {show ? <ViewIcon boxSize="22px" /> : <ViewOffIcon boxSize="22px" />}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage justifyContent="center">{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <FormControl mb="5" isInvalid={!!errors.repeatPassword}>
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Wiederholung"
              {...register("repeatPassword")}
            />
            <InputRightElement width="3.5rem">
              <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                {show ? <ViewIcon boxSize="22px" /> : <ViewOffIcon boxSize="22px" />}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage justifyContent="center">{errors.repeatPassword?.message}</FormErrorMessage>
        </FormControl>

        <Box p="5">
          <CustomButton title="Speichern" onClick={handleSubmit(onSubmit)} />
        </Box>
      </Box>
    </Flex>
  );
};
