import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { AccessDenied } from "./AccessDenied";
import { RootState } from "../../store/store";
import { Role } from "../../types";
import { userHasRequiredRole } from "../../utils";

type Props = {
  children: JSX.Element;
  reqRoles: Array<Role>;
};

export const PrivateRoute = ({ children, reqRoles }: Props) => {
  const location = useLocation();

  const { isSignedIn, roles } = useSelector((state: RootState) => state.auth);

  const userHasRole = userHasRequiredRole(roles, reqRoles);

  if (!isSignedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isSignedIn && !userHasRole) {
    return <AccessDenied />;
  }

  return children;
};
