import { Alert, AlertIcon, AlertTitle, CloseButton } from "@chakra-ui/react";
import React from "react";

const StyledAlert = ({ error }: Props) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>{error}</AlertTitle>
      <CloseButton position="absolute" right="8px" top="8px" />
    </Alert>
  );
};

type Props = {
  error: string;
};

export default StyledAlert;
