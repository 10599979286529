import React, { useEffect } from "react";
import { CustomModal } from "./CustomModal";
import {
  Button,
  FormControl,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = { isOpen: boolean; onClose: () => void; onSubmit: (data: any) => void; text?: string };

type Input = { note: string };

export const NotesModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, text }) => {
  useEffect(() => {
    if (text) {
      setValue("note", text);
    } else {
      reset();
    }
  }, [text]);

  const schema = yup.object().shape({
    note: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Input>({ resolver: yupResolver(schema), defaultValues: { note: "" } });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Notiz</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <FormControl isInvalid={!!errors.note} mt={4}>
          <Textarea {...register("note")} type="textarea" placeholder="Notiz" height={200} />
          <FormErrorMessage justifyContent="center">{errors.note?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={handleSubmit(onSubmit)}>
          Speichern
        </Button>
        <Button onClick={onClose}>Abbrechen</Button>
      </ModalFooter>
    </CustomModal>
  );
};
