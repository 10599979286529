/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { Button, GridItem, SimpleGrid, Text, Box, Flex } from "@chakra-ui/react";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, Controller } from "react-hook-form";
import { StyledInput } from ".";
import StyledSelect from "./StyledSelect";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { Appraisals } from "../../types";
import StyledAlert from "../StyledAlert/StyledAlert";
import { convertStringToDate } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import { ViewIcon, QuestionIcon } from "@chakra-ui/icons";
import { getRefNr } from "../../store/home/homeActions";


interface Props {
  readonly appraisalDetails: Appraisals[];
  readonly uuidNumber: string | null;
  isEditMode?: boolean;
  appraisalId: number;
  pageID: string | undefined;
}

export const schema = yup.object().shape({
  status: yup.mixed().test("status", "Status is required", (value) => {
    if (!Number.isNaN(value)) return value;
  }),
  client: yup.mixed().test("client", "Client is required", (value) => {
    if (!Number.isNaN(value)) return value;
  }),
  assignee: yup.mixed().test("assignee", "Bearbeiter is required", (value) => {
    if (!Number.isNaN(value)) return value;
  }),
  firstName: yup
    .string()
    .required(),
//    .matches(/^([^0-9]*)$/, "name should not contain numbers "),
  lastName: yup
    .string()
    .required(),
//    .matches(/^([^0-9]*)$/, "name should not contain numbers "),
  visit: yup.date().required("Visit is required"),
});

export const CreateAppraisal = ({ appraisalDetails, uuidNumber, isEditMode, appraisalId, pageID }: Props) => {
  const {
    assignee,
    category: apprasialCategory,
    client,
    firstName,
    id,
    lastName,
    refNr,
    status,
    uuid,
    visit,
  } = appraisalDetails?.[0] || {};

  const appraisal = appraisalDetails.find((appraisal) => appraisal.uuid === pageID);

  const { clients, error, categories, appUsers, statuses } = useSelector((state: RootState) => state.home);

  const {
    register,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useFormContext();

  const find = categories.filter((category) => apprasialCategory && category?.name?.includes(apprasialCategory as any));
  const findAppUsers = appUsers && appUsers.filter((appUser) => appUser.username.includes(assignee as any));
  const findClient = clients && clients.filter((appUser) => appUser.name.includes(client as any));

  const findStatus = statuses && statuses.filter((el) => el.name.includes(status?.name as any));

  useEffect(() => {
    setValue("status", 1, { shouldValidate: false });

    if (isEditMode) {
      setValue("refNr", refNr, { shouldValidate: true });
      setValue("lastName", lastName, { shouldValidate: true });
      setValue("firstName", firstName, { shouldValidate: true });
      setValue("category", find[0]?.id, { shouldValidate: true });
      setValue("assignee", findAppUsers[0]?.id, { shouldValidate: true });
      setValue("client", findClient[0]?.id, { shouldValidate: true });
      setValue("status", findStatus[0]?.id, { shouldValidate: true });
      setValue("visit", convertStringToDate(visit), { shouldValidate: true });
      setValue("uuid", uuid, { shouldValidate: false });
      setValue("address", appraisal?.address || null);
    } else {
      reset();
    }
  }, [isEditMode, appraisalDetails]);

  const ExampleCustomInput = React.forwardRef<any, any>(({ value, onClick, error = false }: any, ref: any) => {
    return (
      <Button
        colorScheme={error ? "red" : "blue"}
        onClick={onClick}
        variant={error && "outline"}
        ref={ref}
        error={error}
      >
        {value}
      </Button>
    );
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const onGetRefNr = async () => {
    const id = appraisal?.id;
    dispatch(getRefNr({id:id, cb: (refNr: number) =>  setValue("refNr", refNr ),
    }))
  };


  return (
    <Box maxW="1200" margin="0 auto">
      {error && <StyledAlert error={error} />}
      <SimpleGrid columns={10} spacingX="10px" spacingY="10px">
        <GridItem colSpan={1}>
          <StyledInput
            text="Ref Nr"
            {...register("refNr", { valueAsNumber: false })}
            placeholder="Ref Nr"
            type="number"
            error={!!errors?.refNr}
            helperText={errors?.refNr?.message}
          />
        </GridItem>
        <GridItem colSpan={4}>
        <Flex>
          <Button mt="8" mr="1" onClick={() => onGetRefNr()} disabled={!isEditMode}>
            <QuestionIcon />
          </Button>
          <StyledInput
            text="Vorname"
            {...register("firstName")}
            placeholder="Vorname"
            type="text"
            error={!!errors?.firstName}
            helperText={errors?.firstName?.message}
          />
          </Flex>
        </GridItem>
        <GridItem colSpan={5}>
          <StyledInput
            {...register("lastName")}
            text="Familienname"
            placeholder="Familienname"
            type="text"
            error={!!errors?.lastName}
            helperText={errors?.lastName?.message}
          />
        </GridItem>
        <GridItem colSpan={10}>
          <StyledInput
            type="text"
            text="Adresse"
            {...register("address", { valueAsNumber: false })}
            placeholder="address"
            error={!!errors?.address}
            helperText={errors?.address?.message}
          />
        </GridItem>
        <GridItem colSpan={5}>
          <StyledSelect
            type="number"
            {...register("client", { valueAsNumber: true })}
            text="Auftraggeber"
            placeholder="Open this select menu"
            data={clients}
            error={!!errors?.client}
            helperText={errors?.client?.message}
          />
        </GridItem>
        <GridItem colSpan={5}>
          <StyledSelect
            {...register("status", { valueAsNumber: true })}
            text="Status"
            data={statuses}
            placeholder="Open this select menu"
            error={!!errors?.status}
            helperText={errors?.status?.message}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Text mr="5">Besichtigung</Text>
          <Flex mt="2" border={"1px solid #E2E8F0"} borderRadius={5}>
            <Controller
              control={control}
              name="visit"
              render={({ field }) => {
                return (
                  <DatePicker
                    showTimeInput
                    showTimeSelect
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeFormat="HH:mm"
                    onChange={(date: Date) => {
                      return field.onChange(date);
                    }}
                    selected={field.value ? field.value : new Date()}
                    customInput={<ExampleCustomInput error={!!errors?.visit as any} />}
                  />
                );
              }}
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={3}>
          <StyledSelect
            {...register("category", { valueAsNumber: true })}
            text="Kategorie"
            placeholder="Open this select menu"
            data={categories}
            error={!!errors?.category}
            helperText={errors?.category?.message}
          />
        </GridItem>

        <GridItem colSpan={3}>
          <StyledSelect
            type="number"
            {...register("assignee", { valueAsNumber: true })}
            text="Bearbeiter"
            defaultValue="urgent"
            placeholder="Open this select menu"
            data={appUsers.filter((appUser) => appUser.locked === false)}
            error={!!errors?.assignee}
            helperText={errors?.assignee?.message}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Flex mt="8" justifyContent="right">
            <Button colorScheme="blue" type="submit">
              Speichern
            </Button>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex mt="8" justifyContent="right">
            <Button onClick={() => navigate(`/gallery/${pageID}`)} disabled={!isEditMode}>
              <ViewIcon />
            </Button>
          </Flex>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
