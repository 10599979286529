import { Box, Flex, HStack, useDisclosure, useColorModeValue, Stack, IconButton } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";

import Search from "../Search/Search";
import { DropDownButton } from "../DropDownButton";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { RootState } from "../../store/store";

const Links = ["Home", "Admin", "About"];
const PublicLinks = ["About"];

const Navbar = () => {

  const isSignedIn = useSelector((state:RootState) => state.auth.isSignedIn)

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnClick = (item: string) => {
    if (item === "Abmelden") {
      onLogout();
    }
    if (item === "Passwort ändern") {
      navigate("/changePassword");
    }
  };

  const onLogout = async () => {
    await dispatch(logout());
    navigate("../login");
    location.reload();
  };

  return (
    <nav style={{position: "fixed", width: "100%", top: 0, zIndex:1, color: "white"}}>
      <Box bg={useColorModeValue("secondary.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
            style={{color: "black"}}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box color={"white"} fontWeight={700}>
              <NavLink to="/">
                Gutachten
              </NavLink>
            </Box>
            <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }} color={"white"}>
              {isSignedIn? (
                Links.map((link) => (
                  <NavLink to={`/${link.toLocaleLowerCase()}`} key={link}>
                    {link}
                  </NavLink>
                ))
                ) : (
                  PublicLinks.map((link) => (
                    <NavLink to={`/${link.toLocaleLowerCase()}`} key={link}>
                      {link}
                    </NavLink>
                  ))
                )
              }
            </HStack>
          </HStack>
          {isSignedIn?(
          <>
            <Search />
            <Flex alignItems={"center"} style={{color: "black"}}>
              <DropDownButton label="Menü" items={["Abmelden", "Passwort ändern"]} onClick={handleOnClick} />
            </Flex>
          </>
          ):""}
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {isSignedIn? (
                Links.map((link) => (
                  <NavLink to={`/${link.toLocaleLowerCase()}`} key={link}>
                    {link}
                  </NavLink>
                ))
              ) : (
                PublicLinks.map((link) => (
                  <NavLink to={`/${link.toLocaleLowerCase()}`} key={link}>
                    {link}
                  </NavLink>
                ))
              )
              }
            </Stack>
          </Box>
        ) : null}
      </Box>
    </nav>
  );
};

export { Navbar };
