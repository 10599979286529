import React, { useEffect } from "react";
import { Flex, Box, Input, Text, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/auth/authActions";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Credentials } from "../types";
import { CustomButton } from "../components/CustomButton";
import { CustomLoading } from "../components/CustomLoading";
import { useNotification } from "../hooks";

export const Login: React.FC = () => {
  const { error, isSignedIn, isLoading: loadingAuth } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToast = useNotification();

  const onSignIn = (username: string, password: string) => {
    dispatch(
      login({
        username: username,
        password: password,
        cb: () => showToast({ title: "Success", description: "Logged in", status: "success" }),
      })
    );
  };

  useEffect(() => {
    isSignedIn && navigate("../home");
  }, [isSignedIn]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credentials>();
  const onSubmit: SubmitHandler<Credentials> = (data) => onSignIn(data.username, data.password);

  if (loadingAuth) {
    return <CustomLoading />;
  }

  return (
    <Flex color="black" flexDirection="column" align="center" justify="center" height="calc(100vh - 144px)">
      <Box p="5">
        <Text fontSize="2xl">Sign In</Text>
      </Box>
      {error && <Text color="red">{error}</Text>}
      <Box p="4" align="center" justifyContent="space-between">
        <FormControl mb="5" isInvalid={!!errors.username}>
          <Input placeholder="Login" {...register("username", { required: true })} />
          <FormErrorMessage justifyContent="center">Login is required</FormErrorMessage>
        </FormControl>

        <FormControl mb="5" isInvalid={!!errors.password}>
          <Input placeholder="Password" {...register("password", { required: true })} type="password" />
          <FormErrorMessage justifyContent="center">Password is required</FormErrorMessage>
        </FormControl>
        <Box p="5">
          <CustomButton title="Login" onClick={handleSubmit(onSubmit)} />
        </Box>
      </Box>
    </Flex>
  );
};
