import {
  ArrowUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  ExternalLinkIcon,
  LockIcon,
} from "@chakra-ui/icons";
import { Table as ChakraTable, Thead, Tbody, Tr, Th, Td, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { usePagination, useTable, useSortBy } from "react-table";
import { AppUsers } from "../../types";
import { CustomLoading } from "../CustomLoading";
import ReactTooltip from "react-tooltip";

type Props = {
  columns: any[];
  data: any[];
  admin?: boolean;
  resetPassword?: (data: AppUsers) => void;
  onOpen?: any;
  onRowPress?: (uuid: string, data?: any) => void;
  onDelete?: (data: any) => void;
  onDownload?: (data: any) => void;
  onEdit?: (data: any) => void;
  ref?: any;
  loading?: any;
  actionBtn?: boolean;
  pagination?: boolean;
  edit?: boolean;
  paginateSize?: number;
  getToolTip?: (data: any) => void;
  isNote?: boolean;
  onFileEdit?: (data: any) => void;
  sortedBy?: any;
};

const defaultSortBy = [{ id: "id", desc: false }];

const Table = React.forwardRef(
  ({
    columns,
    data,
    admin,
    resetPassword,
    actionBtn,
    onOpen,
    ref,
    onRowPress,
    edit,
    loading,
    pagination,
    onDelete,
    onEdit,
    onDownload,
    getToolTip,
    onFileEdit,
    paginateSize,
    isNote,
    sortedBy,
  }: Props) => {
    if (loading) {
      return <CustomLoading />;
    }

    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: paginateSize ? paginateSize : 10,
          sortBy: sortedBy ? sortedBy : defaultSortBy,
        },
      },
      useSortBy,
      usePagination
    );
    const {
      getTableProps,
      page,
      state: { pageIndex, pageSize },
      getTableBodyProps,
      headerGroups,
      rows,
      gotoPage,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      prepareRow,
    } = tableInstance;

    return (
      <>
        {ReactTooltip.rebuild()}
        <ChakraTable ref={ref} variant="simple" {...getTableProps()}>
        <ReactTooltip effect="solid" html={true} />
          <Thead>
            {headerGroups.map((headerGroup, i, arr) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i, array) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={
                      isNote && i === array.length - 1 ? { textAlign: "end" } : { textAlign: "start", color: "black" }
                    }
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronUpIcon />
                        )
                      ) : (
                        <ArrowUpDownIcon />
                      )}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                  <Tr {...row.getRowProps()} data-tip={getToolTip && row && getToolTip(row.original)}>
                    {row.cells.map((cell: any, i: any, arr: any) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          style={
                            isNote && i === arr.length - 1
                              ? { textAlign: "left", width: "0%" }
                              : {
                                  textAlign: "start",
                                  color: "black",
                                  maxWidth: "300px",
                                }
                          }
                        >
                          <Flex>
                            {onFileEdit && i === arr.length - 1 && (
                              <Button colorScheme="yellow" mr="2" onClick={() => onFileEdit(row.original)}>
                                <EditIcon boxSize="22" />
                              </Button>
                            )}
                            {onDownload && i === arr.length - 1 && (
                              <Button colorScheme="green" mr="2" onClick={() => onDownload(row.original)}>
                                <DownloadIcon boxSize="22" />
                              </Button>
                            )}
                            {onDelete && !isNote && i === arr.length - 1 && (
                              <Button colorScheme="red"
                                onClick={() => {
                                  window.confirm("Wirklich löschen?") && onDelete(row.original);
                                }}
                              >
                                <DeleteIcon boxSize="22" />
                              </Button>
                            )}
                          </Flex>
                          {actionBtn && i === arr.length - 1 ? (
                            isNote ? (
                              <Flex>
                                <Button
                                  colorScheme="yellow"
                                  mr="2"
                                  onClick={() => onEdit && onEdit(row.original)}
                                >
                                  <EditIcon boxSize="22"/>
                                </Button>
                                <Button colorScheme="red"
                                  onClick={() => {
                                    window.confirm("Wirklich löschen?") && onDelete && onDelete(row.original);
                                  }}
                                >
                                  <DeleteIcon boxSize="22"/>
                                </Button>
                              </Flex>
                            ) : admin ? (
                              <>
                                <Button mr="5" onClick={() => onRowPress && onRowPress(row.original, edit)}>
                                  <EditIcon />
                                </Button>
                                <Button nav onClick={() => resetPassword && resetPassword(row.original)}>
                                  <LockIcon />
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button mr="5" onClick={() => onRowPress && onRowPress(row.original, edit)}>
                                  <EditIcon />
                                </Button>
                                <Button mr="5" nav onClick={() => onRowPress && onRowPress(row.original)}>
                                  <ExternalLinkIcon />
                                </Button>
                              </>
                            )
                          ) : isNote ? (
                            <Text whiteSpace={"pre-line"}>{row.original.text}</Text>
                          ) : (
                            cell.render("Cell")
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
        {pagination && data.length ? (
          <Flex justifyContent="center" m="10">
            <Button onClick={() => previousPage()} disabled={!canPreviousPage} mr="10">
              {"<"}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </Button>
          </Flex>
        ) : null}
      </>
    );
  }
);
Table.displayName = "Table";

export { Table };
