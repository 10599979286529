import { ModalContent, ModalOverlay, Modal as ChakraModal, ModalProps } from "@chakra-ui/react";
import React from "react";

interface Props extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  size?: string;
}

export const CustomModal: React.FC<Props> = ({ isOpen, onClose, children, size, ...props }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <>
      <ChakraModal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        {...props}
      >
        <ModalOverlay />
        <ModalContent>{children}</ModalContent>
      </ChakraModal>
    </>
  );
};
