import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import FileSaver from "file-saver";
import { AppraisalAtachments, AppUsers, Categories, IRole, RefNr, Statuses } from "../../types";
import { AttachmentType, Client, Order } from "../../types/home";
import { convertStringToDate, userHasRequiredRole } from "../../utils";
import { convertClientsData } from "../../utils/helpers/convertClients";

const filterStates = {
  myEntries: "Meine Aufträge",
  plannedEntries: "Geplante Aufträge",
  openEntries: "Offene Aufträge",
  todaysEntries: "Heutige Aufträge",
  allEntries: "Alle Aufträge",
};

const getFileExtentionName = (file: string) => file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2).replace(" ", "").toLowerCase();

const filterByOrder = (filterKey: Order, data: any, auth: any) => {
  switch (filterKey) {
    case filterStates.myEntries: {
      return data.filter((appraisal: any) => {
        const assignedToRole = appraisal.status.assignedToRole?.name;
        return (
          convertStringToDate(appraisal.visit) <= new Date() &&
          (appraisal.assignee?.username.includes(auth.username) || userHasRequiredRole(auth.roles, [assignedToRole])) &&
          appraisal.status?.closedState === false
        );
      });
    }
    case filterStates.plannedEntries: {
      return data.filter(
        (appraisal: any) => (convertStringToDate(appraisal.visit) > new Date() || appraisal?.status?.name == "Geplant")  && 
        appraisal?.status?.closedState === false
      );
    }
    case filterStates.todaysEntries: {
      const start = new Date();
      start.setUTCHours(0,0,0,0);

      const end = new Date();
      end.setUTCHours(23,59,59,999);
      return data.filter(
          (appraisal: any) => (convertStringToDate(appraisal.visit) > start && convertStringToDate(appraisal.visit) < end)  && 
          appraisal?.status?.closedState === false
      );
    }

    case filterStates.openEntries: {
      return data.filter((appraisal: any) => appraisal?.status?.closedState === false ?? null);
    }
    case filterStates.allEntries: {
      return data;
    }
    default:
      throw new Error("No action found!");
  }
};

export const fetchAppraisals = createAsyncThunk<
  any,
  {
    clientId?: number;
    uuid?: string;
    categoryId?: number;
    statusId?: number;
    assigneeId?: number;
    filter?: Order;
  }
>("home/fetchAppraisals", async ({ clientId, uuid, categoryId, statusId, assigneeId, filter }, { getState }) => {
  const { auth } = getState() as any;
  const params = {};
  clientId && Object.assign(params, { ["client.id"]: clientId });
  uuid && Object.assign(params, { uuid: uuid });
  categoryId && Object.assign(params, { ["category.id"]: categoryId });
  statusId && Object.assign(params, { ["status.id"]: statusId });
  assigneeId && Object.assign(params, { ["assignee.id"]: assigneeId });
  filter && filter !== "Alle Aufträge" && Object.assign(params, { ["status.closedState"]: 0 });

  const { data } = await axios.get(`/api/appraisals`, { params: params });

  const filteredData = filter && filterByOrder(filter, data, auth);
  const res = await convertClientsData(filter ? filteredData : data);
  return res;
});

export const fetchPublicAppraisal = createAsyncThunk<any, { uuid: string }>(
  "home/fetchPublicAppraisal",
  async ({ uuid }) => {
    const { data } = await axios.get(`/api/appraisals/public?appraisal=${uuid}`);
    const res = await convertClientsData([data]);
    return res;
    }
);

export const fetchPublicAppraisalAttachments = createAsyncThunk<AppraisalAtachments[], { uuid: string }>(
  "home/fetchAppraisalAttachments",
  async (params, { rejectWithValue }) => {
    try {
      const { uuid } = params;
      const { data } = await axios.get(`/api/appraisal_attachments/public?appraisal=${uuid}`);
      const sortedArray = data.sort(
        (a: AppraisalAtachments, b: AppraisalAtachments) => a.attachmentType.sortOrder - b.attachmentType.sortOrder
      );
      return sortedArray;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAppraisalAttachments = createAsyncThunk<AppraisalAtachments[], { uuid: string }>(
  "home/fetchAppraisalAttachments",
  async (params, { rejectWithValue }) => {
    try {
      const { uuid } = params;
      const { data } = await axios.get(`/api/appraisal_attachments?appraisal.uuid=${uuid}`);

      const photos = ["jpg", "jpeg", "png"];

      const filterdData = data.filter( function (item: { file: string; }) {
        return photos.some((photo) => {
          return getFileExtentionName(item.file) === photo;
        });
      });
  
      const sortedArray = filterdData.sort(
        (a: AppraisalAtachments, b: AppraisalAtachments) => a.attachmentType.sortOrder - b.attachmentType.sortOrder
      );
      return sortedArray;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchPublicFile = createAsyncThunk<any, { id: number }>("home/fetchFile", async (params) => {
  const { id } = params;
  const { data } = await axios.get(`/api/files/${id}/public`, {
    responseType: "arraybuffer",
  });
  const photo = Buffer.from(data, "binary").toString("base64");
  return { id: id, photo: photo };
});

export const fetchFile = createAsyncThunk<any, { id: number }>("home/fetchFile", async (params) => {
  const { id } = params;
  const { data } = await axios.get(`/api/files/${id}`, {
    responseType: "arraybuffer",
  });
  const photo = Buffer.from(data, "binary").toString("base64");
  return { id: id, photo: photo };
});

export const downloadFile = createAsyncThunk<void, { id: number; fileName: string; publicUrl?: boolean }>(
  "home/downloadFile",
  async (params) => {
    const { id, fileName, publicUrl } = params;
    const url = publicUrl ? `/api/files/${id}/public_download` : `/api/files/${id}/download`;
    const { data } = await axios.get(url, {
      responseType: "blob",
    });

    FileSaver.saveAs(data, fileName);
  }
);

export const fetchRoles = createAsyncThunk<IRole[]>("home/fetchRoles", async () => {
  const { data } = await axios.get("/api/roles");
  return data;
});

export const fetchAppUsers = createAsyncThunk<AppUsers[]>("home/fetchAppUsers", async (_, { dispatch }) => {
  // const getRoles = await dispatch(fetchRoles());
  // const roles = getRoles.payload;
  const { data } = await axios.get("/api/users");

  await dispatch(fetchRoles());
  return data;
});

export const getRefNr = createAsyncThunk<RefNr[], { id?: number, cb: (refNr: number) => void}>(
  "home/refNr",
  async ({id, cb}) => {
  const { data } = await axios.get(`/api/appraisals/${id}/ref_nr`);
  cb(data.refNr);
  return data;
});

export const fetchAttachmentTypes = createAsyncThunk<AttachmentType[]>(
  "home/fetchAttachmentTypes",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/attachment_types`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchClients = createAsyncThunk<Client[]>("home/fetchClients", async (_) => {
  const { data } = await axios.get(`/api/clients`);
  return data;
});

export const fetchCategories = createAsyncThunk<Categories>("home/fetchCategories", async (_) => {
  const { data } = await axios.get(`/api/categories`);
  return data;
});

export const fetchStatuses = createAsyncThunk<Statuses>("home/fetchStatuses", async (_) => {
  const { data } = await axios.get(`/api/statuses`);
  return data;
});

export const search = createAsyncThunk<any, { query: string }>("home/search", async (tab) => {
  const params = {};
  const { query } = tab;
  query && Object.assign(params, { ["search"]: query });
  const { data } = await axios.get(`/api/appraisals`, { params: params });
  const res = await convertClientsData(data);
  return res;
});

export const updateAppraisal = createAsyncThunk<void, { id: number; formData: any; cb: () => void }>(
  "home/updateAppraisal",
  async ({ id, formData, cb }, { rejectWithValue }) => {
    try {
      Object.keys(formData).forEach((k) => (formData[k] = formData[k] === "" ? null : formData[k]));
      await axios.patch(`/api/appraisals/${id}`, formData, {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      });
      cb();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
