import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { AppraisalAtachments, draftNote, FileFormType, FileType, Note } from "../../types";
import { formatDateToTimestamp } from "../../utils";

export const sendNote = createAsyncThunk<Note, { notes: Note; cb: () => void }>(
  "formActions/sendNote",
  async ({ notes, cb }) => {
    const { data } = await axios.post(`/api/notes`, {
      appraisal: notes.appraisal.id,
      text: notes.text,
      createdAt: formatDateToTimestamp(notes.createdAt),
    });
    cb();
    return data;
  }
);

export const deleteNote = createAsyncThunk<number, { id: number; cb: () => void }>(
  "formActions/deleteNote",
  async ({ id, cb }) => {
    await axios.delete(`/api/notes/${id}`);
    cb();
    return id;
  }
);

export const deleteFile = createAsyncThunk<number, { id: number; cb: () => void }>(
  "formActions/deleteFile",
  async ({ id, cb }) => {
    await axios.delete(`/api/files/${id}`);
    cb();
    return id;
  }
);

export const updateNote = createAsyncThunk<Note, draftNote>("formActions/updateNote", async (notes) => {
  const { id, appraisal, text, updatedAt, cb } = notes;
  const { data } = await axios.patch(
    `/api/notes/${id}`,
    {
      id: id,
      appraisal: appraisal,
      text: text,
      updatedAt: formatDateToTimestamp(updatedAt),
    },
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );
  cb && cb();
  return data;
});

export const fetchNotes = createAsyncThunk<any, number>("formActions/fetchNotes", async (appraisalId) => {
  const { data } = await axios.get(`/api/notes?appraisal.id=${appraisalId}`);
  return data;
});

export const sendFile = createAsyncThunk<any, FileFormType>(
  "formActions/sendFile",
  async (
    { file, description, fileName, category, categoryId, appraisalId, cb, attachedBy },
    { dispatch, rejectWithValue }
  ) => {
    const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");

    const formData = new FormData();

    formData.append("file", file);
    formData.append("description", description);
    formData.append("fileName", nameWithoutExtension);

    try {
      const { data } = await axios({
        method: "post",
        url: `/api/files/appraisal/${appraisalId}/type/${categoryId}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      const draftFile = {
        id: data.id,
        file: fileName,
        description: description,
        category: category,
        attachedBy: attachedBy,
        attachedAt: new Date(),
      };
      cb && cb();
      return draftFile;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchFiles = createAsyncThunk<void, number>("fileForm/getUserFile", async (appraisalId) => {
  const { data } = await axios.get(`/api/appraisal_attachments?appraisal.id=${appraisalId}`);

  return data.map((el: AppraisalAtachments) => {
    return {
      id: el.id,
      file: el.file,
      description: el.description,
      category: el.attachmentType,
      attachmentType: el.attachmentType,
      attachedAt: el.attachedAt,
      attachedBy: el.attachedBy,
    };
  });
});

export const updateFile = createAsyncThunk<
  FileType,
  { id: number; category: { id: number; name: string }; description: string; cb: () => void }
>("formActions/updateFile", async ({ id, category, description, cb }) => {
  const { data } = await axios.patch(
    `/api/appraisal_attachments/${id}`,
    {
      description: description,
      attachmentType: category,
    },
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );
  cb();
  return data;
});
