import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppUsers, PasswordChange } from "../../types";
import { logout } from "../auth";
import { updateUserState } from "../home";

export const createUser = createAsyncThunk<void, { data: AppUsers; cb: () => void }>(
  "user/createUser",
  async ({ data, cb }, { rejectWithValue }) => {
    try {
      await axios.post("/api/users/create", data);
      cb && cb();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk<void, { user: AppUsers; cb: () => void }>(
  "user/updateUser",
  async ({ user, cb }, { rejectWithValue, dispatch }) => {
    try {
      const params = {
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role.id,
        locked: user.locked,
      };
      const { data } = await axios.patch(`/api/users/${user.id}`, params, {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      });
      dispatch(updateUserState(data));
      cb && cb();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePassword = createAsyncThunk<void, { passwords: PasswordChange; cb: () => void }>(
  "user/changePassword",
  async ({ passwords, cb }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post("/api/users/change_password", passwords);
      dispatch(logout());
      cb && cb();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk<{ newPassword: string }, { id: number; cb: () => void }>(
  "user/resetPassword",
  async ({ id, cb }) => {
    const { data } = await axios.post(`/api/users/change_user_password/${id}`);
    cb();
    return data;
  }
);
