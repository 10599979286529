import { AttachmentType, Client } from "./home";

export type Assignee = {
  id: Pick<Appraisals, "id">;
  username: string;
};

export type Appraisals = {
  assignee?: Assignee;
  client?: Client;
  firstName: string;
  id: number;
  lastName: string;
  refNr: string | null;
  status?: Status;
  uuid: string;
  visit: any;
  address?: string | null;
  category?: {
    id: number;
    name: string;
  };
  createdAt?: any;
  createdBy?: AppUsers;
};

export type AppraisalAtachments = {
  id: number;
  appraisal: {
    id: number;
    uuid: string;
  };
  description: string;
  file: string;
  attachmentType: AttachmentType;
  attachedBy: {
    id: number;
    username: string;
  };
  attachedAt: string;
};

export type Images = { id: number; photo: string };

export type Category = { id: number; name: string; sortOrder: number };
export type Categories = Category[];

export type Status = {
  id: number;
  name: string;
  assignedToRole: {
    id: number;
  };
  todaySortOrder: number;
  sortOrder: number;
  closedState: boolean;
};

export type Statuses = Status[];

export type Note = {
  id: number;
  appraisal: {
    id: number;
  };
  text: string;
  createdBy: {
    id: number;
    username: string;
  };
  createdAt: Date;
  updatedBy?: {
    id: number;
    username: string;
  };
  updatedAt?: Date;
};

export type draftNote = {
  id?: number;
  appraisal: number;
  text: string;
  updatedAt?: Date;
  createdAt?: Date;
  cb?: () => void;
};

type roleName = "ROLE_ADMIN" | "ROLE_APPRAISER" | "ROLE_TRANSKRIPT";

export interface IRole {
  name: string | roleName;
  isAdmin: boolean;
  id: number;
}

export interface RefNr {
  refNr: number;
}

export interface AppUsers {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  role: IRole;
  locked: boolean;
  lockedName?: string;
  password?: string;
  roleName?: string;
}

export type ErrorType = {
  code: number;
  apiResponse: string;
  message: string;
};

export enum ErrorEnum {
  SESSION_NOT_FOUND = "Session not found",
  TOKEN_NOT_FOUND = "JWT Token not found",
  EXPIRED_TOKEN = "Expired JWT Token",
  UNAUTHORIZED_CODE = 401,
}

export type Toast = {
  title: string;
  description: string;
  status: "info" | "warning" | "success" | "error" | undefined;
  duration?: number;
  isClosable?: boolean;
};

export type FileType = {
  id: number;
  file: string;
  description: string;
  category: { id: number; name: string };
  attachedBy?: { id: number; username: string };
  attachedAt?: Date;
  message?: string;
};
export type FileFormType = {
  file: string | Blob;
  description: string;
  fileName: string;
  category: { id: number; name: string };
  categoryId: number;
  appraisalId: number;
  attachedBy?: { id: number; username: string };
  attachedAt?: Date;
  cb?: () => void;
};

export type UploadFileType = {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  lastModifiedDate: Date;
  message?: string;
};

export type Filter = {
  label: string | undefined;
  value: number | undefined;
  item: any;
};
