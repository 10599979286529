import { RepeatIcon } from "@chakra-ui/icons";
import { Button, Flex, HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateAssigneeFilter,
  updateCategoryFilter,
  updateClientFilter,
  updateOrderFilter,
  updateStatusFilter,
} from "../../store/filters";
import { fetchAppraisals } from "../../store/home";
import { RootState } from "../../store/store";

import { DropDownButton } from "../DropDownButton";
import { ExportToExcel } from "../ExportToExcel";

import { NewDropDown } from "../NewDropDown";

const TableControls = ({ data }: { data: any }) => {
  const { clients, categories, statuses, appUsers, loading } = useSelector((state: RootState) => state.home);
  const { client, category, status, assignee, filter } = useSelector((state: RootState) => state.filters);

  const dispatch = useDispatch();

  const filterAppraisals = () => {
    dispatch(
      fetchAppraisals({
        clientId: client?.id,
        categoryId: category?.id,
        statusId: status?.id,
        assigneeId: assignee?.id,
        filter: filter ? filter : "Meine Aufträge",
      })
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    filterAppraisals();
  }, [client, category, status, assignee, filter]);

  return (
    <Flex>
      <Flex flexGrow="1">
        <HStack spacing="24px">
          <DropDownButton
            label={filter}
            items={["Meine Aufträge", "Geplante Aufträge", "Heutige Aufträge", "Offene Aufträge",  "Alle Aufträge"]}
            onClick={(item) => dispatch(updateOrderFilter({ filter: item }))}
          />
          <Button onClick={() => filterAppraisals()}
                  backgroundColor={"primary.400"}
                  color={"white"}
          ><RepeatIcon boxSize="22"/></Button>

          <ExportToExcel fileName="Appraisals" apiData={data} />
          <Button onClick={() => navigate("/create")}>Neuer Auftrag</Button>
        </HStack>
      </Flex>

      <HStack spacing="24px">
        <NewDropDown
          value={client}
          items={clients}
          isLoading={loading}
          label={"Auftraggeber"}
          onClick={(item) => dispatch(updateClientFilter({ client: item ? item : undefined }))}
        />
        <NewDropDown
          value={category}
          items={categories}
          isLoading={loading}
          label={"Kategorie"}
          onClick={(item) => dispatch(updateCategoryFilter({ category: item ? item : undefined }))}
        />

        <NewDropDown
          value={status}
          items={statuses}
          label={"Status"}
          isLoading={loading}
          onClick={(item) =>
            dispatch(
              updateStatusFilter({
                status: item ? item : undefined,
              })
            )
          }
        />

        <NewDropDown
          value={assignee}
          items={appUsers.filter((appUser) => appUser.locked === false)}
          isLoading={loading}
          label={"Bearbeiter"}
          onClick={(item) => dispatch(updateAssigneeFilter({ assignee: item ? item : undefined }))}
        />
      </HStack>
    </Flex>
  );
};

React.forwardRef(TableControls);

export { TableControls };
