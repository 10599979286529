/* eslint-disable react/display-name */
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/react";
import React from "react";

type Props = {
  placeholder: string;
  type: string;
  error: any;
  helperText?: string;
  text: string;
  maxW?: string;
};

export const StyledInput = React.forwardRef<any, Props>(
  ({ placeholder, type, error, helperText, text, maxW, ...props }, ref) => {
    return (
      <FormControl isInvalid={error}>
        <FormLabel>{text}</FormLabel>
        <Input {...props} ref={ref} placeholder={placeholder} backgroundColor="white" maxW={maxW==undefined?"":maxW} type={type} />
        <FormErrorMessage>{helperText}</FormErrorMessage>
      </FormControl>
    );
  }
);
