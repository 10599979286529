import { Button } from "@chakra-ui/button";
import { Flex } from "@chakra-ui/layout";
import React, { ErrorInfo } from "react";

export class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //TODO: send error on external analitic server
  }
  render() {
    if (this.state.hasError) {
      return (
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <h1>Something went wrong.</h1>
          <Button onClick={() => this.setState({ hasError: false })}>Try again</Button>
        </Flex>
      );
    }
    return this.props.children;
  }
}
