import { Box } from "@chakra-ui/layout";
import React from "react";
import { Navbar } from "../../components/Navbar";

const MainWrapper: React.FC = ({ children }) => {
  return (
    <>
      <Navbar />
      <Box mt={35} p="10">{children}</Box>
    </>
  );
};

export { MainWrapper };
