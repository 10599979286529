import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPublicAppraisalAttachments, fetchRoles } from ".";
import { AppraisalAtachments, Appraisals, AppUsers, Categories, Images, IRole, Statuses, Toast } from "../../types";
import { AttachmentType, Client } from "../../types/home";
import {
  fetchAppraisals,
  fetchAppUsers,
  fetchCategories,
  fetchClients,
  fetchFile,
  search,
  fetchAttachmentTypes,
  downloadFile,
  fetchStatuses,
  fetchPublicAppraisal,
  updateAppraisal,
} from "./homeActions";

export interface HomeState {
  appraisals: Appraisals[];
  clients: Client[];
  loading: boolean;
  searching: boolean;
  searchResult: boolean;
  error: string;
  appUsers: AppUsers[];
  appraisalDetails: AppraisalAtachments[];
  appraisalImages: Images[];
  categories: Categories;
  statuses: Statuses;
  attachmentTypes: AttachmentType[];
  downloadingImage: boolean;
  roles: IRole[];
  notification: { isShowed: boolean; details: Toast };
}

const initialState: HomeState = {
  appraisals: [
    {
      id: 0,
      refNr: null,
      firstName: "",
      lastName: "",
      visit: new Date(Date.now()),
      uuid: "",
    },
  ],
  loading: false,
  searching: false,
  searchResult: false,
  error: "",
  appUsers: [],
  appraisalDetails: [],
  appraisalImages: [],
  attachmentTypes: [],
  downloadingImage: false,
  roles: [],
  statuses: [],
  categories: [
    {
      id: 1,
      name: "",
      sortOrder: 1,
    },
  ],
  clients: [
    {
      id: 0,
      name: "",
      tenant: {
        id: 0,
        name: "",
      },
    },
  ],
  notification: { isShowed: false, details: { title: "", description: "", status: undefined } },
};
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    addUserToState: (state: HomeState, action: PayloadAction<AppUsers>) => {
      state.appUsers.push(action.payload);
    },
    updateUserState: (state: HomeState, action: PayloadAction<AppUsers>) => {
      const { id } = action.payload;
      const foundId = state.appUsers.findIndex((x) => x.id === id);
      state.appUsers[foundId] = action.payload;
    },
    showToast: (state: HomeState, action: PayloadAction<Toast>) => {
      state.notification = { isShowed: true, details: action.payload };
    },
    hideToast: (state: HomeState) => {
      state.notification = { isShowed: false, details: initialState.notification.details };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppraisals.fulfilled, (state: HomeState, action: PayloadAction<Appraisals[]>) => {
        state.appraisals = action.payload;
        state.searchResult = false;
        state.loading = false;
      })
      .addCase(fetchAppraisals.rejected, (state: HomeState, action: any) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchAppraisals.pending, (state: HomeState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchPublicAppraisal.fulfilled, (state: HomeState, action: PayloadAction<Appraisals[]>) => {
        state.appraisals = action.payload;
        state.loading = false;
      })
      .addCase(fetchPublicAppraisal.rejected, (state: HomeState, action: any) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(search.fulfilled, (state: HomeState, action: PayloadAction<Appraisals[]>) => {
        state.searching = false;
        state.searchResult = true;
        state.appraisals = action.payload;
      })
      .addCase(search.pending, (state: HomeState) => {
        state.searching = true;
        state.error = "";
      })
      .addCase(search.rejected, (state: HomeState, action: any) => {
        state.searching = false;
        state.error = action.error.message;
      })
      .addCase(fetchClients.fulfilled, (state: HomeState, action: PayloadAction<Client[]>) => {
        state.clients = action.payload;
        state.loading = false;
      })
      .addCase(fetchClients.pending, (state: HomeState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchClients.rejected, (state: HomeState, action: any) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchRoles.fulfilled, (state: HomeState, action: PayloadAction<IRole[]>) => {
        state.roles = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoles.pending, (state: HomeState) => {
        state.error = "";
        // state.loading = true;
      })
      .addCase(fetchRoles.rejected, (state: HomeState, action: any) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchStatuses.fulfilled, (state: HomeState, action: PayloadAction<Statuses>) => {
        state.statuses = action.payload;
        state.loading = false;
      })
      .addCase(fetchStatuses.pending, (state: HomeState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchStatuses.rejected, (state: HomeState, action: any) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchCategories.fulfilled, (state: HomeState, action: PayloadAction<Categories>) => {
        state.categories = action.payload;
        state.loading = false;
      })
      .addCase(fetchCategories.pending, (state: HomeState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchCategories.rejected, (state: HomeState, action: any) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchAppUsers.fulfilled, (state: HomeState, action: PayloadAction<AppUsers[]>) => {
        state.appUsers = action.payload;
        state.loading = false;
      })
      .addCase(fetchAppUsers.pending, (state: HomeState, action: any) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAppUsers.rejected, (state: HomeState, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFile.fulfilled, (state: HomeState, action: PayloadAction<{ id: number; photo: string }>) => {
        const newTab = [...state.appraisalImages];
        newTab.push(action.payload);
        state.appraisalImages = newTab;
      })
      .addCase(fetchFile.rejected, (state: HomeState, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPublicAppraisalAttachments.rejected, (state: HomeState, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(
        fetchPublicAppraisalAttachments.fulfilled,
        (state: HomeState, action: PayloadAction<AppraisalAtachments[]>) => {
          state.appraisalDetails = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchPublicAppraisalAttachments.pending, (state: any, action: any) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAttachmentTypes.fulfilled, (state: any, action: PayloadAction<AttachmentType[]>) => {
        state.loading = false;
        state.attachmentTypes = action.payload;
      })
      .addCase(fetchAttachmentTypes.pending, (state: any, action: any) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAttachmentTypes.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(downloadFile.pending, (state: HomeState, action: PayloadAction<any>) => {
        state.downloadingImage = true;
        state.error = "";
      })
      .addCase(downloadFile.fulfilled, (state: HomeState, action: PayloadAction<any>) => {
        state.downloadingImage = false;
        state.error = "";
      })
      .addCase(downloadFile.rejected, (state: HomeState, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateAppraisal.pending, (state: HomeState, action: PayloadAction<any>) => {
        state.error = "";
      })
      .addCase(updateAppraisal.fulfilled, (state: HomeState, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(updateAppraisal.rejected, (state: HomeState, action: any) => {
        state.loading = false;
        state.error = action.payload?.message ?? action.error.message;
      });
  },
});

export const { addUserToState, updateUserState } = homeSlice.actions;

export default homeSlice.reducer;
