import React from "react";
import packageJson from '../../package.json';

const About = () => {
  return <div>
<p style={{ fontSize: 30, color: "#ED8936", textAlign: "center", paddingTop: "10px" }}>OODA Gutachten Manager </p>

<p style={{ textAlign: "center", paddingTop: "4px" }}><a style={{ color: "blue", textDecoration: "underline blue"}} href="https://help.gutachten.ooda.at/de/changelog" target="_blank" rel="noreferrer">Version {packageJson.version}</a>   </p>
<table>
  <tr>
    <td style={{padding: "30px" }}>
      <img src="/audit-ga9800b02d_640.png"/>
    </td>
    <td style={{padding: "30px" }}>
      <p style={{ paddingTop: "10px" }}>Der Gutachten Manager ist eine Software für Sachverständige zur Erfassung und Verwaltung von Gutachten und Besichtigungsaufträgen. Eine Kombination aus einer Web Oberfläche und eine Android Applikation ermöglicht es, die Gutachten zu jeder Zeit bearbeiten zu können.</p>
      <p style={{ paddingTop: "10px" }}>Mit Hilfe der Android App kann ein Gutachten direkt vor Ort bei einer Besichtigung angelegt und/oder bearbeitet werden. Weiters besteht die Möglichkeit, Bilder, Texte und Audio Aufnahmen direkt in der Android App an das Gutachten anzuhängen. Damit kann die Zeit der Nachbearbeitung erheblich reduziert werden und Gutachten können schneller und effizienter erstellt werden.</p>
      <p style={{ paddingTop: "10px" }}>Durch die Web Oberfläche können Gutachten bequem von jedem Gerät bearbeitet und mittels eines Workflows effizient auch in größeren Teams verwaltet werden</p>
    </td>
  </tr>
</table>
<p style={{ paddingTop: "10px" }}><a style={{ color: "blue", textDecoration: "underline blue"}} href="https://help.gutachten.ooda.at/" target="_blank" rel="noreferrer">Hilfe und Beschreibung</a></p>

<p style={{ paddingTop: "10px" }}>Kontakt und Betreiber: <a style={{ color: "blue", textDecoration: "underline blue"}} href="https://ooda.at/" target="_blank" rel="noreferrer">OODA Consulting e.U.</a></p>

<p style={{ paddingTop: "10px" }}><a style={{ color: "blue", textDecoration: "underline blue"}} href="https://help.gutachten.ooda.at/de/dsgvo" target="_blank" rel="noreferrer">Datenschutz</a> / <a style={{ color: "blue", textDecoration: "underline blue"}} href="https://help.gutachten.ooda.at/de/kontakt" target="_blank" rel="noreferrer">Impressum</a></p>

<p style={{ paddingTop: "10px" }}>Entwickelt mit der Unterst&uuml;tzung von&nbsp;<a href="https://mdevelopers.com/" target="_blank" rel="noreferrer"><img src="https://mdevelopers.com/assets/mdevelopers.svg" alt="" width="154" height="32" /></a></p>

</div>;
};

export { About };
