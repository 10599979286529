import React, { useEffect } from "react";
import { CustomModal } from "./CustomModal";
import {
  Button,
  FormControl,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Flex,
  Text,
  Box,
  Input,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Checkbox } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomButton } from "../CustomButton";
import { AppUsers } from "../../types";
import { convertToFriendlyName } from "../../utils";
import { DropDownButton } from "../DropDownButton";

type Props = { isOpen: boolean; onClose: () => void; onSubmit: (data: any) => void; user?: AppUsers };

type Input = { note: string };

export const UserModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, user }) => {
  const { error, loading } = useSelector((state: RootState) => state.user);
  const { roles } = useSelector((state: RootState) => state.home);
  const isEditMode = !!user;
  const schema = yup.object().shape({
    username: yup.string().required(),
    firstName: yup
      .string()
      .matches(/^([^0-9]*)$/, "name should not contain numbers ")
      .required(),
    lastName: yup
      .string()
      .matches(/^([^0-9]*)$/, "name should not contain numbers ")
      .required(),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<AppUsers>({
    defaultValues: { role: { id: 1, name: "Admin", isAdmin: true } },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEditMode) {
      setValue("username", user.username);
      setValue("id", user.id);
      setValue("role", user.role);
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
      setValue("locked", user.locked);
    } else {
      reset();
    }
  }, [user]);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalHeader borderBottom="1px solid" borderBottomColor="grey">
        {isEditMode ? "Bearbeiten" : "Benutzer anlegen"}
      </ModalHeader>
      <ModalCloseButton />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody pb={6}>
          {error && (
            <Flex align="center" justify="center">
              <Text color="red">{error}</Text>
            </Flex>
          )}
          <Flex color="black" flexDirection="row" align="center" justify="center">
            <Box p="4" align="center" justifyContent="center">
              <Flex justifyContent="center" alignItems="center" mb="5">
                <FormControl isInvalid={!!errors.username} isDisabled={isEditMode}>
                  <FormLabel>Username</FormLabel>
                  <Input placeholder="Username" {...register("username")} />
                  <FormErrorMessage justifyContent="center">{errors.username?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.role}>
                  <FormLabel textAlign="center">Rolle</FormLabel>
                  <Controller
                    render={({ field }) => {
                      return (
                        <DropDownButton
                          items={convertToFriendlyName(roles)}
                          label={field.value.name}
                          onClick={(item) => {
                            field.onChange(item);
                          }}
                        />
                      );
                    }}
                    name="role"
                    control={control}
                    defaultValue={{ id: 1, name: "Admin", isAdmin: true }}
                  />
                  <FormErrorMessage justifyContent="center">{errors.role?.id?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex flexDirection="row" align="center" justify="center" my="5">
                <FormControl isInvalid={!!errors.locked}>
                  <Checkbox {...register("locked")}>Gesperrt</Checkbox>
                </FormControl>
              </Flex>
              {!isEditMode && (
                <Flex>
                  <FormControl mb="5" isInvalid={!!errors.password}>
                    <FormLabel>Passwort</FormLabel>
                    <Input placeholder="Password" {...register("password")} type="password" required />
                    <FormErrorMessage justifyContent="center">{errors.password?.message}</FormErrorMessage>
                  </FormControl>
                </Flex>
              )}
              <Flex>
                <FormControl mr="5" isInvalid={!!errors.firstName}>
                  <FormLabel>Vorname</FormLabel>
                  <Input placeholder="Vorname" {...register("firstName")} />
                  <FormErrorMessage justifyContent="center">{errors.firstName?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.lastName}>
                  <FormLabel>Familienname</FormLabel>
                  <Input placeholder="Familienname" {...register("lastName")} />
                  <FormErrorMessage justifyContent="center">{errors.lastName?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <CustomButton type="submit" title="Speichern" m={5} isLoading={loading} />
          <Button onClick={onClose}>Abbrechen</Button>
        </ModalFooter>
      </form>
    </CustomModal>
  );
};
