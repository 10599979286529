export const USERS_TABLE_COLUMNS = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Username",
    accessor: "username",
  },

  {
    Header: "Vorname",
    accessor: "firstName",
  },
  {
    Header: "Familienname",
    accessor: "lastName",
  },
  {
    Header: "Rolle",
    accessor: "roleName",
  },
  {
    Header: "Gesperrt",
    accessor: "lockedName",
  },
  {
    Header: "Password reset",
    accessor: "PasswordReset",
  },
];
