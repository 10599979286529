import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/layout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/auth";
import { CustomButton } from "../CustomButton";

interface Props {
  error: string;
  onPress?: () => void;
}

export const ShowError: React.FC<Props> = ({ error, onPress }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = async () => {
    await dispatch(logout());
    navigate("../login");
    location.reload();
  };
  return (
    <Flex justifyContent="center" alignItems="center" height="calc(100vh - 144px)" flexDirection="column">
      <WarningTwoIcon h={20} w={20} />
      <Text fontSize="3xl" mt="5">
        Oops! Something went wrong.
      </Text>
      <Text fontSize="xl" mt="5">
        {error}
      </Text>
      <Flex flexDirection="row">
        <CustomButton title="Nochmals..." onClick={onPress ? onPress : onLogout} margin={15} />
        <CustomButton title="Neu anmelden" onClick={onLogout} margin={15} />
      </Flex>
    </Flex>
  );
};
