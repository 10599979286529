import { applyMiddleware, combineReducers, createStore } from "@reduxjs/toolkit";
import homeReducer from "./home/homeSlice";
import authReducer from "./auth/authSlice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import formReducer from "./Form/formSlice";
import filtersReducer from "./filters/filtersSlice";
import userReducer from "./user/userSlice";

const rootReducer = combineReducers({
  home: homeReducer,
  auth: authReducer,
  form: formReducer,
  filters: filtersReducer,
  user: userReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunkMiddleware];

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
