import React from "react";
import { CustomModal } from "./CustomModal";
import { Flex, ModalCloseButton } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

type Props = { isOpen: boolean; onClose: () => void; photo: string };

export const PhotoModal: React.FC<Props> = ({ isOpen, onClose, photo }) => {
  const height = window.innerHeight;

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} size={"6xl"} isCentered>
      <ModalCloseButton backgroundColor={"primary.400"} color="white" />

      <Flex alignItems="center" justifyContent="center">
        <Image src={`data:image/jpeg;base64,${photo}`} maxH={height - 10} />
      </Flex>
    </CustomModal>
  );
};
