import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@chakra-ui/react";
import { Appraisals } from "../../types";

type Props = {
  apiData: any;
  fileName: any;
};

const ExportToExcel = ({ apiData, fileName }: Props) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData: any, fileName: any) => {

    const exportData = apiData.map((el: Appraisals) => {
      return {
        id: el.id,
        refNr: el.refNr,
        client: el.client,
        firstName: el.firstName,
        lastName: el.lastName,
        address: el.address,
        visit: el.visit,
        status: el.status?.name,
        assingee: el.assignee,
        category: el.category,
        uuid: el.uuid,
      };
    })

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      <Button
        onClick={(e) => exportToCSV(apiData, fileName)}
        backgroundColor={"primary.400"}
        color={"white"}
      >
        Export table
      </Button>
    </div>
  );
};

export { ExportToExcel };
