export type Credentials = {
  username: string;
  password: string;
  cb?: () => void;
};

export interface Tokens {
  token: string;
  refreshToken: string;
}

export interface DecodedToken {
  exp: Date;
  iat: Date;
  roles: string[];
  username: string;
}

export enum Role {
  Admin = "ROLE_ADMIN",
  Appraiser = "ROLE_APPRAISER",
  Transkript = "ROLE_TRANSKRIPT",
  User = "ROLE_USER",
}

export type PasswordChange = { oldPassword: string; password: string; repeatPassword: string };
