import { Appraisals } from "../types";

export const APPRAISALS_MOCK: Appraisals = {
  firstName: "",
  id: 0,
  lastName: "",
  refNr: null,
  address: null,
  uuid: "",
  visit: new Date(),
};

export const ACCEPTED_FILE_TYPES = [
  "JPG",
  "JPEG",
  "PNG",
  "MP3",
  "MP4",
  "PDF",
  "DOC",
  "DOCX",
  "XLS",
  "XLSX",
  "ZIP",
  "ds2",
];
