import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import React from "react";
import { Category, IRole, Status } from "../../types";
import { Client } from "../../types/home";
import type { Ref } from "react";

type Props<T> = {
  items: T[];
  label?: string;
  onClick?: (item: T) => void;
};

const _DropDownButton = <T extends string | Client | Category | Status | IRole>(
  { items, label = "menu", onClick, ...props }: Props<T>,
  ref: Ref<HTMLButtonElement>
) => {
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        backgroundColor={"primary.400"}
        color={"white"}
      >
        {label}
      </MenuButton>
      <MenuList {...props}>
        <MenuOptionGroup type="radio">
          {items.map((item) =>
            typeof item === "string" ? (
              <MenuItemOption
                {...props}
                value={item}
                onClick={() => onClick && onClick(item) && onClick}
              >
                {item}
              </MenuItemOption>
            ) : (
              <MenuItem
                ref={ref}
                {...props}
                onClick={() => onClick && onClick(item)}
              >
                {item.name}
              </MenuItem>
            )
          )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

const DropDownButton = React.forwardRef(
  _DropDownButton
) as typeof _DropDownButton;

export { DropDownButton };
