import { WarningIcon } from "@chakra-ui/icons";
import { Box, Center } from "@chakra-ui/layout";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShowError } from "../components/ErrorBoundary/ShowError";
import { Table } from "../components/Table";
import { TableControls } from "../components/TableControls";
import { MAIN_TABLE_COLUMNS } from "../constants/mainTableColumns";
import {
//  fetchAppraisals,
  fetchAppUsers,
  fetchCategories,
  fetchClients,
  fetchStatuses,
} from "../store/home/homeActions";
import { RootState } from "../store/store";

import { Appraisals, Role } from "../types";
import { userHasRequiredRole } from "../utils";
import dateFormat from "dateformat";

export type Status = {
  id: Pick<Appraisals, "id">;
  name: string;
};
export type Client = {
  id: Pick<Appraisals, "id">;
  name: string;
};


const getToolTip = (data: any) => {
  return "Addresse: " + (data.address?data.address:" -- nicht verfügbar --") + "<br/>"+
  (data.createdAt ? " Angelegt: " + dateFormat(data.createdAt, "dd.mm.yyyy HH:MM", true) : "") + "<br/>"+
  (data.createdBy ? " Von: " + data.createdBy.username : "")
};

const Home = () => {
  const [fetched, setFetched] = useState<boolean>(false);
  const { appraisals, error, searchResult } = useSelector((state: RootState) => state.home);
  const { isSignedIn, roles } = useSelector((state: RootState) => state.auth);

  const sortBy = [{ id: "Besuchsdatum", desc: searchResult }];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
//    await dispatch(fetchAppraisals({}));
//    if (roles[1] === "ROLE_ADMIN") {
      await dispatch(fetchAppUsers());
//    }
    await dispatch(fetchClients());
    await dispatch(fetchCategories());
    await dispatch(fetchStatuses());

    setFetched(true);
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, isSignedIn]);

  const data = React.useMemo(() => appraisals, [appraisals]);
  const columns = React.useMemo(() => MAIN_TABLE_COLUMNS, []);
  const tableRef = useRef(null);

  const onRowPress = (data: any, edit?: boolean) => {
    if (userHasRequiredRole(roles, [Role.Admin, Role.Appraiser, Role.Transkript])) {
      edit ? navigate(`/edit/${data.uuid}`) : navigate(`/${data.uuid}`);
    }
  };

  if (error) {
    return <ShowError error={error} onPress={fetchData} />;
  }

  return (
    <>
      <TableControls data={data} />           
      <small>{data.length } Datensätze gefunden </small>

      <Box marginTop="10">
        {!appraisals.length ? (
          <Center h="400px" color="black">
            <WarningIcon w={8} h={8} />
            <Box ml={5}>Keine passenden Elemente gefunden!</Box>
          </Center>
        ) : (
          <Table
            columns={columns}
            data={data}
            ref={tableRef}
            onRowPress={onRowPress}
            loading={!fetched}
            edit={true}
            actionBtn
            paginateSize={100}
            getToolTip={getToolTip}
            pagination
            sortedBy={sortBy}
          />
        )}
      </Box>
    </>
  );
};

export { Home };
