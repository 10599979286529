import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteFile } from ".";
import { AppraisalAtachments, Note } from "../../types";

import { deleteNote, fetchFiles, fetchNotes, sendFile, sendNote, updateFile, updateNote } from "./formActions";

interface InitialState {
  formValues: {
    refNr: string | null;
    status: string;
    visit: Date;
    firstName: string;
    lastName: string;
    client: number;
    category: { id: number; name: string };
    tenant: number;
    assignee: number;
  };
  fileStore: AppraisalAtachments[];
  noteStore: Note[];
  error: any;
  notesError: string;
  loading: boolean;
}

const initialState: InitialState = {
  formValues: {
    refNr: null,
    status: "",
    visit: new Date(),
    firstName: "",
    lastName: "",
    client: 1,
    category: { id: 0, name: "" },
    tenant: 2,
    assignee: 1,
  },
  fileStore: [],
  noteStore: [],
  error: "",
  notesError: "",
  loading: false,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    chooseBase: (state, action) => {
      return {
        ...state.formValues,
        ...action.payload,
      };
    },
    getFiles: (state, action) => {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.fulfilled, (state: InitialState, action: PayloadAction<Note[]>) => {
        state.noteStore = action.payload;
        state.loading = false;
      })
      .addCase(fetchNotes.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchNotes.rejected, (state: InitialState, action: any) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(sendNote.fulfilled, (state: InitialState, action: PayloadAction<Note>) => {
        state.noteStore = state.noteStore.concat(action.payload);
        state.loading = false;
      })
      .addCase(sendNote.pending, (state: InitialState) => {
        state.notesError = "";
        state.loading = true;
      })
      .addCase(sendNote.rejected, (state: InitialState, action: any) => {
        state.notesError = action.error;
        state.loading = false;
      })
      .addCase(sendFile.fulfilled, (state: InitialState, action: PayloadAction<any>) => {
        state.fileStore = state.fileStore.concat(action.payload);
        state.loading = false;
      })
      .addCase(sendFile.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(sendFile.rejected, (state: InitialState, action: any) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateNote.fulfilled, (state: InitialState, action: PayloadAction<Note>) => {
        const { id } = action.payload;
        const foundId = state.noteStore.findIndex((x) => x.id === id);
        state.noteStore[foundId] = action.payload;
        state.loading = false;
      })
      .addCase(updateNote.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(updateNote.rejected, (state: InitialState, action: any) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(deleteNote.fulfilled, (state: InitialState, action: PayloadAction<number>) => {
        state.noteStore = state.noteStore.filter((x) => x.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteNote.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(deleteNote.rejected, (state: InitialState, action: any) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(deleteFile.fulfilled, (state: InitialState, action: PayloadAction<number>) => {
        state.fileStore = state.fileStore.filter((x) => x.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteFile.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(deleteFile.rejected, (state: InitialState, action: any) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(fetchFiles.fulfilled, (state: InitialState, action: any) => {
        state.fileStore = action.payload;
        state.loading = false;
      })
      .addCase(fetchFiles.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchFiles.rejected, (state: InitialState, action: any) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(updateFile.fulfilled, (state: InitialState, action: PayloadAction<any>) => {
        const { id } = action.payload;
        const foundId = state.fileStore.findIndex((x) => x.id === id);
        state.fileStore[foundId] = {
          ...action.payload,
          category: { id: action.payload.attachmentType.id, name: action.payload.attachmentType.name },
        };
        state.loading = false;
      })
      .addCase(updateFile.pending, (state: InitialState) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(updateFile.rejected, (state: InitialState, action: any) => {
        state.error = action.error;
        state.loading = false;
      });
  },
});

export default formSlice.reducer;

export const { chooseBase, getFiles } = formSlice.actions;
