import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./styles/CustomTheme";
import { About, Admin, ChangePassword, Home, Login } from "./pages";
import { MainWrapper } from "./wrappers/MainWrapper";
import { Create } from "./pages/Create";
import { axiosConfig } from "./utils/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/store";
import { AppraisalDetails } from "./pages/AppraisalDetails";
import { PrivateRoute } from "./wrappers/PrivateRoute";
import { Role } from "./types";
import { AppraisalGallery } from "./pages/AppraisalGallery";

function App() {
  const tokens = useSelector((state: RootState) => state.auth.tokens);
  const dispatch = useDispatch();

  axiosConfig(dispatch, tokens);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <MainWrapper>
          <Routes>
            <Route path="*" element={<Navigate to="/home" />} />
            <Route path="/create/*" element={<Create />} />
            <Route
              path="/home"
              element={
                <PrivateRoute reqRoles={[Role.Admin, Role.Appraiser, Role.Transkript]}>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute reqRoles={[Role.Admin]}>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route path="/about" element={<About />} />
            <Route
              path="/create"
              element={
                <PrivateRoute reqRoles={[Role.Admin, Role.Appraiser]}>
                  <Create />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path=":id" element={<AppraisalDetails />} />
            <Route
              path="/changePassword"
              element={
                <PrivateRoute reqRoles={[Role.Admin, Role.Appraiser, Role.Transkript]}>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="edit/:id"
              element={
                <PrivateRoute reqRoles={[Role.Admin, Role.Appraiser, Role.Transkript]}>
                  <Create />
                </PrivateRoute>
              }
            />
            <Route
              path="gallery/:id"
              element={
                <PrivateRoute reqRoles={[Role.Admin, Role.Appraiser, Role.Transkript]}>
                  <AppraisalGallery />
                </PrivateRoute>
              }
            />
          </Routes>
        </MainWrapper>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
